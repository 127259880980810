<template>
  <v-form v-model="valid" ref="type_form" lazy-validation>
    <v-select
      label="Тип аренды"
      :items="rent_type_items"
      append-icon="mdi-tag-text-outline"
      required
      v-model="type"
      :rules="rentTypeRules"
      :readonly="getCompleted()"
    >
    </v-select>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      rent_type_items: [],
      type: null,
      valid: true,
    };
  },

  methods: {
    ...mapGetters([
      "getRentTypeDictionary",
      "getSelectedGameConsoleForRent",
      "getDelivered",
      "getRentType",
      "getCompleted"
    ]),
    ...mapMutations(["updateType"]),

    changeType() {
      this.$emit("changeTypeEvent", this.type);
    },

    validate() {
      this.$refs.type_form.validate();
      return this.valid;
    },
  },

  mounted: function () {
    this.rent_type_items = this.getRentTypeDictionary();
    this.type = this.getRentType();
  },

  computed: {
    rentTypeRules() {
      const rules = [];

      rules.push(() => !!this.type || "Заполните поле");

      let item_console = this.getSelectedGameConsoleForRent();

      if (this.type != null && item_console != null) {
        let rule = (v) =>
          v === item_console.console_type.code.toUpperCase() ||
          `Тип аренды не соответсвует типу консоли`;

        rules.push(rule);
      }

      return rules;
    },
  },

  watch: {
    type(val) {
      this.updateType(val);
      this.changeType();
    },
  },
};
</script>

<style>
</style>