import Vue from 'vue'
import Vuex from 'vuex'
import person from "./person";
import dadata from "./dadata";
import fssp from './fssp';
import dict from "./dict";
import console from "./rent/console";
import console_list from "./rent/console_list";
import predicts from "./rent/predicts"
import rent_person from "./rent/person"
import rent_contract from "./rent/rent_contract"
import rent from "./rent/rent"
import rents_managment from "./rents_managment/rents";
import game_console_edit from "./game_console/game_console";
import gamepads from "./game_console/gamepads";
import login from "./login"
import reports from "./reports";
import justice from "./justice"
import secret_check from "./secret_check";


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    person,
    dadata,
    fssp,
    dict,
    console,
    console_list,
    predicts,
    rent_person,
    rent_contract,
    rent,
    rents_managment,
    game_console_edit,
    gamepads,
    login,
    reports,
    justice,
    secret_check
  }
})
