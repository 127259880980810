<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :loading="loading_fssp"
        :color="color_fssp_btn"
        @click="dialog_fssp = true"
        dark
        x-small
        style="margin-left: 10px"
        v-bind="attrs"
        v-on="on"
      >
        {{ button_text }}
        <template>
          <FsspDialog
            ref="fssp_dialog_form"
            v-on:closeEvent="dialog_fssp = false"
          />
        </template>
      </v-btn>
    </template>
    <span>{{ tooltip_text }}</span>
  </v-tooltip>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import FsspDialog from "../Dialogs/FsspDialog.vue";
export default {
  components: {
    FsspDialog,
  },

  data() {
    return {
      loading_fssp: false,
      dialog_fssp: false,
      color_fssp_btn: "primary",
      fssp_list: [],
      fssp_sum: 0,
      fssp_error: false,
      button_text: "фссп",
    };
  },

  computed: {
    tooltip_text() {
      let text = "";

      if (this.fssp_error) {
        text = "Ошибка при проверке данных по ФССП !";
      } else if (this.fssp_list.length > 0) {
        text = "найдены задолжности по фссп";
      } else if (this.fssp_list.length === 0) {
        text = "долгов по фссп нет";
      }

      return text;
    },
  },

  watch: {
    dialog_fssp(val) {
      if (val) this.$refs.fssp_dialog_form.show();
    },
  },

  methods: {
    ...mapGetters([
      "getSelectedPersonForRent",
      "getFsspItems",
      "getFsspTotalSum",
    ]),
    ...mapActions(["fetchCheckFSSP"]),

    async fsspCheck() {
      if (this.getSelectedPersonForRent() === null) {
        this.color_fssp_btn = "primary";
        return;
      }

      this.loading_fssp = true;
      this.color_fssp_btn = "blue-grey lighten-3";

      try {
        await this.fetchCheckFSSP({
          first_name: this.getSelectedPersonForRent().first_name,
          last_name: this.getSelectedPersonForRent().last_name,
          middle_name: this.getSelectedPersonForRent().middle_name,
          b_day: this.getSelectedPersonForRent().b_day,
        });

        this.fssp_list = this.getFsspItems();
        this.fssp_sum = this.getFsspTotalSum();

        if (this.fssp_list.length > 0) {
          this.color_fssp_btn = "deep-orange darken-2";
          this.button_text = "ФССП!";
        } else {
          this.color_fssp_btn = "green darken-2";
        }
      } catch {
        this.fssp_error = true;
        this.color_fssp_btn = "error";
      }

      this.loading_fssp = false;
    },
  },
};
</script>

<style>
</style>