<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab key="peorder" @change="openPreOrderTab"> Предзаказы </v-tab>
        <v-tab key="active" @change="openActiveRentTab"> Активные</v-tab>
        <v-tab key="completed" @change="openCompletedTab"> Последние завершенные</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
          <PreOrderTab ref="ref_preorder_tab"/>
          <ActiveRentTab ref="ref_active_tab"/>
          <LastCompletedRentTab ref="ref_completed_tab"/>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import PreOrderTab from "./Tabs/PreOrderTabs.vue";
import ActiveRentTab from "./Tabs/ActiveRentTab.vue";
import LastCompletedRentTab from "./Tabs/LastCompletedRentTab.vue";

export default {
  components: {
    PreOrderTab,
    ActiveRentTab,
    LastCompletedRentTab
  },
  data() {
    return {
      tab: null,

    };
  },

  methods:{

      openPreOrderTab(){

          this.$refs.ref_preorder_tab.refresh();
      },

      openActiveRentTab(){

          this.$refs.ref_active_tab.refresh();
      },

      openCompletedTab(){

          this.$refs.ref_completed_tab.refresh();
      }
  }

};
</script>

<style>
</style>