import { apiUrl,  setCookie } from "../conf";

export default {

    actions:{

        async fetchLogin({commit},data){

            let authData = data.username + ":" + data.password;

            let base64Data = window.btoa(authData);

            const result = await fetch(apiUrl + '/api/login',{

                method:'POST',
                headers: { "Authorization": "Basic  " + base64Data },
            });

            if(!result.ok){

                throw new Error(result.status)

            }
            else {

                const authResp = await result.json()

                commit('update', authResp);

                setCookie('token', authResp.token, {'max-age': 720000})

                setCookie('isauth', true, {'max-age': 720000})

                setCookie('tokenDadata', authResp.tokenDadata,  {'max-age': 720000});
                
                setCookie('secretDadata', authResp.secretDadata,  {'max-age': 720000});

                setCookie('regionCode', authResp.regionCode,  {'max-age': 720000});

                setCookie('regionName', authResp.regionName,  {'max-age': 720000});

                window.location.href = '/';
            }
        }

    },

    mutations:{

        updateToken(state, data){

            state.token = data.token;
            state.dadataToken = data.tokenDadata;
            state.secretDadata = data.secretDadata;
            state.regionCode = data.regionCode;
            state.regionName = data.regionName;

        }

    },

    state:{

        token:'',
        dadataToken : '',
        dadataSecret : '',
        regionCode : '',
        regionName : ''
    },

    getters:{

        getToken(state){

            return state.token;
        },

        getDadataToken(state){

            return state.dadataToken;
        },

        getDadataSecret(state){

            return state.dadataSecret;
        },

        getRegionCode(state){
            
            return state.regionCode;
        },

        getRegionName(state){
            
            console.log('getRegionName = ' + state.regionCode);
            return state.regionName;
        }

    }

}