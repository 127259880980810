<template>
  <v-tab-item key="preorder">
    <v-card flat style="margin-top: 10px" :loading="loading">
      <v-card-subtitle>
              <v-card-actions>
        <v-btn x-small color="green" dark href="/rent">Добавить предзаказ</v-btn>
      </v-card-actions>

        Быстрые фильтры:
        <v-card-actions>
          <v-btn-toggle v-model="toggle_exclusive">
            <v-row>
              <v-col lg="3"
                ><v-btn
                  x-small
                  color="blue-grey lighten-5"
                  @click="today_view()"
                  >на сегодня ({{ today_count }})</v-btn
                >
              </v-col>

              <v-col lg="3">
                <v-btn
                  x-small
                  color="blue-grey lighten-5"
                  @click="tomorrow_view()"
                  >на завтра ({{ tomorrow_count }})</v-btn
                >
              </v-col>

              <v-col lg="2">
                <v-btn x-small color="blue-grey lighten-5" @click="ps4_view()"
                  >PS4 ({{ ps4_count }})</v-btn
                >
              </v-col>
              <v-col lg="2">
                <v-btn x-small color="blue-grey lighten-5" @click="ps5_view()"
                  >PS5 ({{ ps5_count }})</v-btn
                >
              </v-col>
              <v-col lg="2">
                <v-btn
                  x-small
                  color="blue-grey lighten-5"
                  @click="all_active_view()"
                  >Все ({{ all_count }})</v-btn
                >
              </v-col>
            </v-row>
          </v-btn-toggle>
        </v-card-actions>

        <v-text-field
          style="height: 50px"
          label="Поиск по фио или телефону"
          outlined
          clearable
          v-model="search_text"
        ></v-text-field>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-container fluid>
        <v-row dense>
          <v-col
            lg="2"
            style="margin-top: 12px"
            v-for="rent in rents"
            :key="rent.id"
          >
            <RentCard
              v-bind:rent="rent"
              v-on:callEventRefreshRents="refresh()"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-tab-item>
</template>

<script>
import RentCard from "../RentCard/RentCardComponent";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    RentCard,
  },
  data() {
    return {
      rents: [],
      all_active_rents: [],
      rents_by_fast_filter: [],
      toggle_exclusive: null,
      toggle_all_filter: 4,
      search_text: "",
      loading: false,
    };
  },

  watch: {
    search_text(val) {
      this.inputSearch(val);
    },

    toggle_exclusive() {
      this.search_text = "";
    },
  },

  computed: {
    today_count() {
      let now = new Date();

      
      return this.all_active_rents.filter((rent) => {
        return this.getNumberOfDays(now, rent.begin_date) === 0;
      }).length;
    },

    tomorrow_count() {
      let now = new Date();

      
      return this.all_active_rents.filter((rent) => {
        return this.getNumberOfDays(now, rent.begin_date) === 1;
      }).length;
    },


    ps4_count() {
      return this.all_active_rents.filter((rent) => {
        return rent.type === "PS4";
      }).length;
    },

    ps5_count() {
      return this.all_active_rents.filter((rent) => {
        return rent.type === "PS5";
      }).length;
    },

    all_count() {
      return this.all_active_rents.length;
    },
  },

  methods: {
    ...mapActions(["fetchGetPreOrderRent"]),
    ...mapGetters(["getPreOrderRents"]),

    async refresh() {
      this.rents = [];
      this.loading = true;

      await this.fetchGetPreOrderRent();

      this.rents = this.getPreOrderRents();
      this.all_active_rents = this.rents;

      this.loading = false;
      this.toggle_exclusive = this.toggle_all_filter;
    },

    getNumberOfDays(start, end) {

      const date1 = new Date(start);
      const date2 = new Date(end);
      date1.setHours(0);
      date1.setMinutes(0);
      date1.setSeconds(0);
      date1.setMilliseconds(0);

      date2.setHours(0);
      date2.setMinutes(0);
      date2.setSeconds(0);
      date2.setMilliseconds(0);


      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      console.log(diffInTime);
      return diffInDays;
    },

    inputSearch(query) {
      if (query === null) {
        this.rents = this.rents_by_fast_filter;
        return;
      }

      let result = [];

      if (query.startsWith("79") || query.startsWith("9")) {
        for (let rent of this.rents_by_fast_filter) {
          if (rent.person.phone_number.includes(query)) {
            result.push(rent);
            continue;
          }
        }
        this.rents = result;
      } else {
        for (let rent of this.rents_by_fast_filter) {
          if (
            rent.person.last_name.toUpperCase().includes(query.toUpperCase()) ||
            rent.person.first_name.toUpperCase().includes(query.toUpperCase())
          )
            result.push(rent);
        }

        this.rents = result;
      }
    },

    today_view() {
      let now = new Date();

      this.rents_by_fast_filter = this.all_active_rents.filter((rent) => {
         return this.getNumberOfDays(now, rent.begin_date) === 0;
      });

      this.rents = this.rents_by_fast_filter;
    },

    tomorrow_view() {
         let now = new Date();

      this.rents_by_fast_filter = this.all_active_rents.filter((rent) => {
            return this.getNumberOfDays(now, rent.begin_date) === 1;
      });

      this.rents = this.rents_by_fast_filter;
    },


    ps4_view() {
      this.rents_by_fast_filter = this.all_active_rents.filter((rent) => {
        return rent.type === "PS4";
      });

      this.rents = this.rents_by_fast_filter;
    },

    ps5_view() {
      this.rents_by_fast_filter = this.all_active_rents.filter((rent) => {
        return rent.type === "PS5";
      });

      this.rents = this.rents_by_fast_filter;
    },

    all_active_view() {
      this.rents_by_fast_filter = this.all_active_rents;
      this.rents = this.all_active_rents;
    },
  },

  mounted: function(){

    this.refresh();
  }


};
</script>

<style>
</style>