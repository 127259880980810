<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel flat>
      <v-expansion-panel-header> Комментарий </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-textarea
          clearable
          clear-icon="mdi-close-circle"
          label="Комментарий"
          append-icon="mdi-comment-outline"
          v-model="description"
          rows="2"
        ></v-textarea>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      description: null,
      panel: -1,
    };
  },

  watch: {
    description(val) {
      this.updateDescription(val);
    },
  },

  methods: {
    ...mapGetters(["getDescription"]),
    ...mapMutations(["updateDescription"]),

    expansionOpen() {
      this.panel = 0;
    },

    expansionClose() {
      this.panel = -1;
    },
  },

  mounted: function () {
    this.description = this.getDescription();

    this.description != null && this.description.length > 0
      ? this.expansionOpen()
      : this.expansionClose();
  },
};
</script>

<style>
</style>