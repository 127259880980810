import { apiUrl, getCookieToken, setCookie } from "../../conf";

export default {

    actions: {

        async fetchGetActiveRent({ commit }) {

            const result = await fetch(apiUrl + '/api/v2/rents/active', {

                method: 'GET',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                let rents = await result.json()

                commit('updateActiveRents', rents)
            }
        },

        async fetchGetPreOrderRent({ commit }) {

            const result = await fetch(apiUrl + '/api/v2/rents/preorder', {

                method: 'GET',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                let rents = await result.json()

                commit('updatePreOrderRents', rents)
            }
        },

        async fetchGetLastCompletedRent({ commit }) {

            const result = await fetch(apiUrl + '/api/v2/rents/completed', {

                method: 'GET',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                let rents = await result.json()

                commit('updateLastCompletedRents', rents)
            }
        },

        async fetchDeleteRentById({ commit }, rent_id) {

            console.log('fetchDeleteRentById ' + rent_id);
            console.log(rent_id);

            const result = await fetch(apiUrl + '/api/v2/rent/' + rent_id, {

                method: 'DELETE',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())
            }
            else {

                commit('updateDeleteSuccess', true);
            }
        },

        async fetchCompletedRentById({ commit }, id) {

            const result = await fetch(apiUrl + '/api/v2/rent/' + id + '/completed', {

                method: 'POST',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())
            }
            else {

                commit('updateCalncelDeliveredSuccess', true);
            }

        },

        async fetchDeliveredRentById({ commit }, id) {

            const result = await fetch(apiUrl + '/api/v2/rent/' + id + '/delivered', {

                method: 'POST',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())
            }
            else {

                commit('updateCalncelDeliveredSuccess', true);
            }
        },

        async fetchCancelDeliveredRentById({ commit }, id) {

            const result = await fetch(apiUrl + '/api/v2/rent/' + id + '/delivered/cancel', {

                method: 'POST',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())
            }
            else {

                commit('updateCalncelDeliveredSuccess', true);
            }
        },

        async fetchAddProlongation({ commit }, data) {

            const result = await fetch(apiUrl + '/api/v2/rent/' + data.rent_id + '/prolongation', {

                method: 'POST',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
                body: JSON.stringify(data),
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())
            }
            else {

                commit('updateCalncelDeliveredSuccess', true);
            }
        },
    },

    mutations: {

        updateActiveRents(state, val) {

            state.active_rents = val;
        },

        updatePreOrderRents(state, val) {

            state.preorder_rents = val;
        },

        updateLastCompletedRents(state, val){

            state.last_completed_rents = val;
        },

        updateDeleteSuccess(state, val) {

            state.delete_success = val;
        },

        updateCalncelDeliveredSuccess(state, val) {

            state.cancel_delivered_success = val;
        }


    },

    state: {

        active_rents: [],
        preorder_rents: [],
        last_completed_rents :[],
        delete_success: false,
        cancel_delivered_success: false,
    },

    getters: {


        getActiveRents(state) {

            return state.active_rents;
        },

        getPreOrderRents(state) {

            return state.preorder_rents;
        },

        getLastCompletedRents(state) {

            return state.last_completed_rents;
        },


    }
}