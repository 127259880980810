<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="black"
        x-small
        style="margin-left: 10px"
        v-bind="attrs"
        v-on="on"
        @click="call"
      >
        <v-icon>mdi-phone</v-icon>
      </v-btn>
    </template>
    <span>позвонить на {{ phone_number }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    ...mapGetters(["getSelectedPersonForRent"]),

    call() {
      window.location.href =
        "tel:+" + this.getSelectedPersonForRent().phone_number;
    },
  },

  computed: {
    phone_number() {
      return this.getSelectedPersonForRent() === null
        ? null
        : this.getSelectedPersonForRent().phone_number;
    },
  },
};
</script>

<style>
</style>