<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="10" lg="10">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-autocomplete
                  ref="last_name"
                  placeholder="Введите фамилию..."
                  v-model="last_name"
                  :rules="[() => !!last_name || 'Заполните поле']"
                  required
                  :loading="loading_last_name"
                  :items="items_last_name"
                  :search-input.sync="search_last_name"
                  label="Фамилия"
                  hide-no-data
                  clearable
                  flat
                  @input="inputLastName"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                  ref="first_name"
                  v-model="first_name"
                  :loading="loading_first_name"
                  :items="items_first_name"
                  :search-input.sync="search_first_name"
                  label="Имя"
                  placeholder="Введите имя..."
                  :rules="[() => !!first_name || 'Заполните поле']"
                  required
                  hide-no-data
                  clearable
                  flat
                  id="input-first-name"
                  @input="inputFirstName"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                  v-model="middle_name"
                  :loading="loading_middle_name"
                  :items="items_middle_name"
                  :search-input.sync="search_middle_name"
                  flat
                  hide-no-data
                  label="Отчество"
                  placeholder="Введите отчество..."
                  :rules="[() => !!middle_name || 'Заполните поле']"
                  clearable
                  id="input-middle-name"
                  @input="inputMiddleName"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="b_day"
                  label="Дата рождения"
                  required
                  type="date"
                  id="input-b-day"
                  clearable
                  @keydown="keyDownBday"
                  :rules="rulesBDay"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="phone"
                  label=" Номер телефона"
                  id="input-phone"
                  required
                  @keypress="keyPressPhone"
                  clearable
                  :rules="phoneRules"
                  @keydown="keyDownPhone"
                >
                </v-text-field>
                <v-btn
                  v-if="phone != null && phone.length === phone_len"
                  x-small
                  color="secondary"
                  dark
                  v-on:click="call"
                  icon
                >
                  <v-icon dark>mdi-phone</v-icon>
                </v-btn>
                <v-btn
                  v-if="phone != null && phone.length === phone_len"
                  x-small
                  color="success"
                  dark
                  style="margin-left: 10px"
                  v-on:click="openWhatsApp"
                  icon
                >
                  <v-icon dark>mdi-whatsapp</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12" md="8">
                <v-text-field
                  id="input-url"
                  v-model="social_url"
                  label="Ссылка на соц. сети"
                  required
                  :append-outer-icon="'mdi-send'"
                  @click:append-outer="openSocial"
                  @keydown="keyDownUrl"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    valid: false,
    first_name: "",
    last_name: "",
    middle_name: "",
    b_day: null,
    phone: null,
    social_url: "",

    loading_last_name: false,
    items_last_name: [],
    search_last_name: null,

    loading_first_name: false,
    items_first_name: [],
    search_first_name: null,

    loading_middle_name: false,
    items_middle_name: [],
    search_middle_name: null,

    phone_len: 11,
  }),

  created: function () {
    this.first_name = this.getFirstName();
    if (this.getFirstName() != null && this.getFirstName() != "")
      this.items_first_name.push(this.getFirstName());

    this.middle_name = this.getMiddleName();
    if (this.getMiddleName() != null && this.getMiddleName() != "")
      this.items_middle_name.push(this.getMiddleName());

    this.last_name = this.getLastName();
    if (this.getLastName() != null && this.getLastName() != "")
      this.items_last_name.push(this.getLastName());

    this.b_day = this.getBday();
    this.phone = this.getPhoneNumber();
    this.social_url = this.getSocialUrl();
  },

  computed: {
    phoneRules() {
      const rules = [];

      if (!this.getPhoneIsValid()) {
        const rule = (v) =>
          (v || "").length === this.phone_len ||
          `Номер телефона должен состоять из ${this.phone_len} цифр`;
        rules.push(rule);
      }

      return rules;
    },

    rulesBDay() {
      const rules = [];
      const DATE_1_JAN_1900 = -2208988800000;

      if (!this.getBdayIsValid()) {
        rules.push((v) => !isNaN(Date.parse(v)) || `Заполните поле`);
        rules.push(
          (v) => Date.parse(v) >= DATE_1_JAN_1900 || `Введите корректную дату`
        );
      }

      return rules;
    },
  },

  watch: {
    search_last_name(val) {
      val && val !== this.last_name && this.searchLastName(val);
    },

    search_first_name(val) {
      val && val !== this.first_name && this.searchFirstName(val);
    },

    search_middle_name(val) {
      val && val !== this.middle_name && this.searchMiddleName(val);
    },

    first_name(newVal, oldVal) {
      if (newVal != oldVal) this.setFirstName(this.first_name);
    },

    last_name(newVal, oldVal) {
      if (newVal != oldVal) this.setLastName(this.last_name);
    },

    middle_name(newVal, oldVal) {
      if (newVal != oldVal) this.setMiddleName(this.middle_name);
    },

    b_day(newVal, oldVal) {
      if (newVal != oldVal) this.setBday(this.b_day);
    },

    phone(newVal, oldVal) {
      if (newVal != oldVal) this.setPhoneNumber(this.phone);
    },

    social_url(newVal, oldVal){
      if(newVal!=oldVal) this.setSocialUrl(this.social_url);
    }
  },

  methods: {
    ...mapGetters([
      "getDataFio",
      "getFirstName",
      "getLastName",
      "getMiddleName",
      "getBday",
      "getPhoneNumber",
      "getSocialUrl",
      "getFirstNameIsValid",
      "getMiddleNameIsValid",
      "getLastNameIsValid",
      "getPhoneIsValid",
      "getBdayIsValid",
      "getPersonObj",
      "getDataFio",
      "getNameFieldsIsValid",
    ]),
    ...mapMutations([
      "setFirstName",
      "setLastName",
      "setMiddleName",
      "setBday",
      "setPhoneNumber",
      "setSocialUrl"
    ]),
    ...mapActions(["fetchSuggestFio"]),

    validate() {
      this.$refs.form.validate();
      return this.valid;
    },

    //Key Events
    keyPressPhone($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      if (
        ((keyCode < 48 || keyCode > 57) && keyCode !== 46) ||
        (this.phone != null && this.phone.length === 11)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    keyDownPhone($event) {
      if ($event.keyCode === 13 && this.getPhoneIsValid()) {
        document.getElementById("input-url").focus();
      }
    },

    keyDownUrl($event) {
      if ($event.keyCode === 13) {
        document.getElementById("input-passport-sereis").focus();
      }
    },

    keyDownBday($event) {
      const DATE_1_JAN_1900 = -2208988800000;

      if ($event.keyCode === 13) {
        let valid = null;

        if (
          isNaN(Date.parse(this.b_day)) ||
          Date.parse(this.b_day) < DATE_1_JAN_1900 ||
          Date.now() < Date.parse(this.b_day)
        )
          valid = false;
        else valid = true;

        if (valid) document.getElementById("input-phone").focus();
      }
    },

    inputLastName() {
      document.getElementById("input-first-name").focus();
    },

    inputFirstName() {
      document.getElementById("input-middle-name").focus();
    },

    inputMiddleName() {
      document.getElementById("input-b-day").focus();
    },

    //Key Events

    call() {
      window.location.href = "tel:+" + this.phone;
    },

    openWhatsApp() {
      window.open(
        "https://api.whatsapp.com/send?phone=" +
          this.phone +
          "&text=Привет. Это на счет аренды PS."
      );
    },

    openSocial() {
      if (this.social_url != null && this.social_url != "")
        window.open(this.social_url);
    },

    //Поиск в DADATA ///////////////////////////////////////
    async searchLastName(search) {
      if (search === "" || search === null) return;

      this.items_last_name = [];
      this.loading_last_name = true;

      await this.fetchSuggestFio(search.replaceAll(" ", ""));

      let list_data = this.getDataFio().filter(
        (x) => x.data.surname != null && x.data.gender === "MALE"
      );

      for (let data of list_data) {
        this.items_last_name.push(data.value);
      }

     
      this.items_last_name.push(search);
      

      this.loading_last_name = false;
    },

    async searchFirstName(search) {
      if (search === "" || search === null) return;

      this.items_first_name = [];
      this.loading_first_name = true;

      await this.fetchSuggestFio(search.replaceAll(" ", ""));

      let list_data = this.getDataFio().filter(
        (x) => x.data.name != null && x.data.gender === "MALE"
      );

      for (let data of list_data) {
        this.items_first_name.push(data.value);
      }

      if (list_data.length === 0) {
        this.items_first_name.push(search);
      }

      this.loading_first_name = false;
    },

    async searchMiddleName(search) {
      if (search === "" || search === null) return;

      this.items_middle_name = [];
      this.loading_middle_name = true;

      await this.fetchSuggestFio(search.replaceAll(" ", ""));

      let list_data = this.getDataFio().filter(
        (x) => x.data.surname != null && x.data.gender === "UNKNOWN"
      );

      for (let data of list_data)
        if (data.value.endsWith("ич")) this.items_middle_name.push(data.value);

      if (this.items_middle_name.length === 0) this.items_middle_name.push(search);

      this.loading_middle_name = false;
    },
  },
};
</script>