<template>
  <div class="text-center" v-if="drgData!=null">
    <v-dialog v-model="dialog" width="700">
      <v-card>

        <span>адрес : {{drgData.FOMSAddress}} </span>
        <span>телефон : {{drgData.PhoneNumbers}}</span>

        <v-list-item
          v-for="(item, index) in drgData.InformationDetal"
          :key="index"
          three-line
        >
          <v-list-item-content>
            <v-list-item-title style="font-weight: bold">{{
              item.exe_production
            }}</v-list-item-title>
            <span>{{new Date(item.CaseDate)
                    .toLocaleString("ru", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      timezone: "UTC",
                    })
                    .substring(0, 10) }}</span>
            <span>{{ item.Cause }}</span>
            <span>{{ item.DiagnosisName }}</span>
            <span>{{item.DoctorOrganizationName}} | {{item.DoctorSubDivision}}</span>
            <span>{{item.SurveyResult}}</span>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {

data(){

    return{

        drgData : null,
        dialog : false,
    }
},

  watch: {
    dialog(val) {
      if (!val) {
        this.$emit("closeEvent");
      }
    },
  },

  methods : {

      ...mapGetters(['getDrg']),

      show(){

          this.dialog = true;
          this.drgData = this.getDrg()[0];
      }
  }

}
</script>

<style>

</style>