import { apiUrl, getCookieToken, setCookie } from "../../conf";

export default {

    actions: {

        async fetchGetAddressesByPerson({commit}, person_id) {

            const result = await fetch(apiUrl + '/api/persons/' + person_id + '/addresses', {

                headers: {

                    Token: getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const item = await result.json()

                commit('updateHistoryAddresses', item)
            }
        },

        async fetchOrderHistoryByPerson({ commit }, person_id) {

            const result = await fetch(apiUrl + '/api/rents/person/' + person_id, {

                method: 'GET',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                let history = await result.json()

                commit('updateOrderHistory', history)
            }
        },

        async fetchFindPersons({commit}, query) {

            const result = await fetch(apiUrl + '/api/persons/find/' + query, {

                method: 'GET',
                headers: {

                    Token: getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const list = await result.json()

                commit('updateFoundPersons', list)
            }
        },

    },

    mutations: {

        updateHistoryAddresses(state, list) {

            state.person_addresses_history = list;
        },

        updateOrderHistory(state, obj) {

            state.order_history = obj;
        },

        updateFoundPersons(state, list){

            state.found_persons = list;
        }

    },

    state: {

        person_addresses_history: [],
        order_history: [],
        found_persons : [],

    },

    getters: {

        getAddressesHistoryByPerson(state) {

            return state.person_addresses_history;
        },

        getOrderHistoryByPerson(state) {

            return state.order_history.reverse();
        },

        getFoundPersons(state){

            return state.found_persons;
        }

    }
}