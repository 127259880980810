import { apiUrl } from "../conf";

export default {

    actions: {

        async fetchCheckJustice({ commit }, person) {

            const result = await fetch(apiUrl + '/api/justice_check?firstname=' + person.first_name + "&secondname=" + person.middle_name + "&lastname=" + person.last_name,
                {

                    method: 'GET',
                    mode: "cors",
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: { "Content-Type": "application/json" },
                });

            if (result.ok) {

                let json = await result.json();

                commit('updateJustice', json)
            }
            else{

                commit('clearJusticeList');

                throw new Error('Ошибка при проверка по судопроизводствам');
            }
        },
    },

    mutations: {

        updateJustice(state, data) {

            state.list = data
        },

        clearJusticeList(state){

            state.list = [];
        }
    },

    state: {

        list: [],
    },

    getters: {

        getJusticeItems(state) {

            return state.list;
        },



    }
}