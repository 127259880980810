<template>
  <v-card
    max-width="175"
    class="mx-auto"
    :color="getColorCard"
    style="margin-top: 10px"
  >
    <v-row class="mx-auto" style="height: 20px">
      <v-col>
        <v-list-item-title
          style="font-size: 11px; font-weight: bolder; width: 100%"
        >
          {{ rent_info_title }}
        </v-list-item-title>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="9">
        <v-list-item-title style="font-size: 17px; font-weight: bolder">
          {{
            game_console.console_type.code.toUpperCase() +
            " | № " +
            game_console.number
          }}
        </v-list-item-title>
      </v-col>
      <v-col cols="3">
        <v-btn
          style="width: 100%"
          icon
          fab
          color="info"
          x-small
          :href="'/console/' + game_console.id"
          ><v-icon>mdi-circle-edit-outline</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title style="font-size: 12px">
          геймпады | {{ game_console.gamepads.length }} шт.
        </v-list-item-title>
        <v-divider></v-divider>
        <v-list-item-title style="font-size: 12px">
          игры | {{ game_console.games.length }} шт.
        </v-list-item-title>
        <v-divider></v-divider>
          <v-list-item-title
          style="font-size: 10px"
        >
          s/n: {{game_console.serial_number}}
        </v-list-item-title>
          <v-list-item-title
          style="font-size: 10px"
        >
          model: {{game_console.model}}
        </v-list-item-title>
          <v-divider></v-divider>
        <v-list-item-title
          v-if="game_console.permanent_net_connection"
          style="font-size: 12px"
        >
          нужен интернет
        </v-list-item-title>

      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-row class="mx-auto">
      <v-col class="mx-auto">
        <v-btn style="width: 100%" x-small text @click="copyMsg()"
          >скопировать описание</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    game_console: Object,
  },

  computed: {
    getColorCard() {
      let grey = "grey lighten-5";
      let yellow = "yellow lighten-4";
      let dark_grey = "red lighten-4";
      let purple = "purple lighten-4";

      let result = grey;

      if (this.game_console.current_rent != null) result = yellow;
      else if (!this.game_console.active) result = dark_grey;
      else if (this.game_console.booked) result = purple;

      return result;
    },

    rent_info_title() {
      let result = null;

      if (this.game_console.current_rent != null)
        result =
          "до " +
          this.game_console.current_rent.end_date.substring(0, 5) +
          " у " +
          this.game_console.current_rent.person.last_name +
          " " +
          this.game_console.current_rent.person.first_name;
      if (this.game_console.booked)
        result =
          "предзаказ на " +
          this.game_console.preorder.begin_date.substring(0, 5);
      if (!this.game_console.active) result = "не активна! (нельзя выдать)";

      return result;
    },
  },

  methods: {
    copyMsg() {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = this.fast_msg();
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },

    fast_msg() {
      let textMsg = "";
      let permanent_net_connection_text = this.game_console
        .permanent_net_connection
        ? "Для работы приставки потребуется постоянное интернет соединение (WI-FI или кабель)"
        : this.net_connection_allowed
        ? ""
        : "Запрещено подключать к интернету";

      let games_text = "";

      for (let game of this.game_console.games) games_text += game + ",";

      textMsg =
        this.game_console.console_type.Name +
        " ( №" +
        this.game_console.number +
        ")" +
        "\r\n" +
        permanent_net_connection_text +
        "\r\n" +
        "Геймады :" +
        this.game_console.gamepads.length +
        " шт." +
        "\r\n\r\n" +
        "Игры : " +
        games_text +
        "\r\n\r\n" +
        this.game_console.description +
        "\r\n";
      return textMsg;
    },
  },
};
</script>

<style>
</style>