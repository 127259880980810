<template>
  <div>
    <v-autocomplete
      v-model="address"
      :loading="loading_address"
      :items="getAddressItems"
      :search-input.sync="search_address"
      flat
      hide-no-data
      hide-details
      label="Адрес доставки"
      id="input-address"
      clearable
      no-filter
      required
      append-icon="mdi-map-marker-outline"
    ></v-autocomplete>
    <v-btn
      v-if="address != '' && (address != null) & !address_error"
      x-small
      color="success"
      dark
      style="margin-top: 10px"
      v-on:click="doubleGisOpen"
      >2 GIS
      <v-icon dark small>mdi-map-search</v-icon>
    </v-btn>
    <v-btn
      v-if="address != '' && (address != null) & !address_error"
      x-small
      color="warning"
      dark
      style="margin-top: 10px; margin-left: 10px"
      v-on:click="googleMapsOpen"
      >Google maps
      <v-icon dark small>mdi-map-search</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      loading_address: false,
      items_address: [],
      search_address: "",
      address_error: false,
      address: null,
      address_history: [],
      select_history_address: null,
      visable_history_address: true,
    };
  },

  computed: {
    getAddressItems() {
      let list = [];

      if (this.visable_history_address)
        for (let adr of this.address_history) list.push(adr);

      for (let adr of this.items_address) list.push(adr);

      return list;
    },
  },

  watch: {
    loading_address(newVal, oldVal) {
      if (
        newVal === true &&
        oldVal === false &&
        this.address_history.length > 0
      )
        this.visable_history_address = false;
      //уже начался поиск других адресов. значит не будет показывать
      //те которые были в истории у этого клиента
    },

    search_address(val) {
      val && val !== this.address && this.searchAddress(val);
    },

    select_history_address(val) {
      if (val != null) {
        this.items_address = [];
        this.items_address.push(val);
        this.address = val;
      }
    },

    address(val) {
      this.updateDeliveryAddress(val);
    },
  },

  created: function () {
    if (this.getDeliveryAddress() != null) {
      this.address = this.getDeliveryAddress();
      this.items_address.push(this.getDeliveryAddress());
    }
  },

  methods: {
    ...mapActions(["fetchSuggestAddress", "fetchGetAddressesByPerson"]),
    ...mapGetters([
      "getDataAddress",
      "getAddressesHistoryByPerson",
      "getDeliveryAddress",
      "getRegionName"
    ]),
    ...mapMutations(["updateDeliveryAddress"]),

    doubleGisOpen() {
      window.open("https://2gis.ru/lipetsk/search/" + this.address);
    },

    googleMapsOpen() {
      window.open("https://www.google.com/maps/place/" + this.address);
    },

    async searchAddress(_search) {
      if (_search === "") return;

      this.items_address = [];
      this.loading_address = true;

      await this.fetchSuggestAddress(_search);

      let list_data = this.getDataAddress();

      for (let data of list_data)
        this.items_address.push(data.unrestricted_value);

      if (list_data.length === 0) this.items_address.push(_search);

      this.loading_address = false;
    },

    async loadHistoryAddress(person_id) {
      if (this.getDeliveryAddress() != null) return;

      this.items_address = [];
      this.address_history = [];

      this.loading_address = true;
      await this.fetchGetAddressesByPerson(person_id);
      this.address_history = this.getAddressesHistoryByPerson();

      this.loading_address = false;
    },
  },
};
</script>

<style>
</style>