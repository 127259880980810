<template>
<v-tab-item key="period">
  <div>
    <div style="max-width: 900px; margin: auto">
      <v-overlay :value="overlay_visable">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>

    <v-card class="mx-auto">
      <v-card class="mx-auto">
        <v-card-title>За период</v-card-title>
        <v-card-subtitle>
          <v-row justify="center">
            <v-col lg="3" md="4" sm="12">
              <v-text-field
                type="date"
                label="Дата начала"
                v-model="begin_date"
              ></v-text-field>
              <v-btn
                x-small
                color="light-green lighten-4"
                style="margin-left: 10px"
                @click="currentMonth()"
                >За тек. месяц</v-btn
              >
              <v-btn
                x-small
                color="light-green lighten-4"
                style="margin-left: 10px"
                @click="previousMonth()"
                >За прошлый месяц</v-btn
              >
            </v-col>

            <v-col lg="3" md="4" sm="12">
              <v-text-field
                type="date"
                label="Дата окончания"
                v-model="end_date"
              >
              </v-text-field>
            </v-col>
            <v-col lg="1" md="4" sm="12">
              <v-select
                label="Тип"
                :items="rent_type_items"
                required
                v-model="rent_type"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col lg="2" md="4" sm="12">
              <v-btn @click="load()" small color="primary">Загрузить</v-btn>
            </v-col>
          </v-row>
        </v-card-subtitle>
      </v-card>
      <v-card v-if="loaded">
        <v-card-title>Результат</v-card-title>
        <v-card-subtitle >
          <v-row>
            <v-col cols="3"
              ><span>Всего аренд: {{ total_rent_count }}</span></v-col
            >
            <v-col cols="3"
              ><span v-if="rent_stats != null"
                >От новых клиентов: {{ rent_stats.new_person_count }}</span
              ></v-col
            >
            <v-col cols="3"
              ><span v-if="rent_stats != null"
                >Выручка: {{ rent_stats.total_price }} р.</span
              ></v-col
            >
          </v-row>
        </v-card-subtitle>
        <v-row justify="center" style="margin-top: 50px">
          <v-card
            max-width="400"
            max-height="500"
            style="margin-right: 10px"
          >
            <v-card-title
              >выручка по дням | {{ total_price_by_date }} руб.</v-card-title
            >
            <div style="width: 400px; height: 500px">
              <bar-chart :chart-data="data_by_day" v-if="loaded"></bar-chart>
            </div>
          </v-card>
          <v-card
            max-width="400"
            max-height="500"
            style="margin-right: 10px"
          >
            <v-card-title
              >выручка по дням недели |
              {{ total_price_by_date }} руб.</v-card-title
            >
            <div style="width: 400px; height: 500px">
              <bar-chart
                :chart-data="data_by_weekday"
              ></bar-chart>
            </div>
          </v-card>

          <v-card
            max-width="400"
            max-height="500"
            style="margin-right: 10px"
          >
            <v-card-title
              >выручка по консолям |
              {{ total_price_by_date }} руб.</v-card-title
            >
            <div style="width: 400px; height: 500px">
              <bar-chart
                :chart-data="data_by_consoles"
              ></bar-chart>
            </div>
          </v-card>

          <v-card
            max-width="400"
            max-height="500"
            v-if="loaded"
            style="margin-right: 10px"
          >
            <v-card-title
              >источники заявок | {{ total_rent_count }} шт.</v-card-title
            >
            <div style="width: 400px; height: 500px">
              <bar-chart
                :chart-data="data_by_source"
              ></bar-chart>
            </div>
          </v-card>
        </v-row>
      </v-card>
    </v-card>
  </div>
</v-tab-item>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BarChart from "./BarChart";

export default {
  components: { BarChart },
  data() {
    return {
      begin_date: null,
      end_date: null,
      rent_type: "ВСЕ",
      rent_type_items: ["PS4", "PS5", "ВСЕ"],
      overlay_visable: false,
      loaded: false,
      chartdata: null,
      total_price_by_date: 0,
      data_by_consoles: null,
      data_by_weekday: null,
      data_by_day: null,
      data_by_source: null,
      total_rent_count: 0,
      rent_stats: null,
    };
  },
  methods: {
    ...mapActions([
      "fetchGetIncomePriceByConsole",
      "fetchGetIncomePriceByWeekDay",
      "fetchGetIncomePriceByDay",
      "fetchGetRentSourceStats",
      "fetchGetRentStats",
    ]),
    ...mapGetters([
      "getIncomePriceByConsoleList",
      "getIncomePriceByWeekDayList",
      "getIncomePriceByDayList",
      "getRentSourceStats",
      "getRentStats",
    ]),

    loadIncomePriceByDayList() {
      let list_by_day = this.getIncomePriceByDayList();

      let days = [];
      let days_price = [];
      for (let row of list_by_day) {
        if (row.day != undefined) {
          let date = new Date(row.day);
          days.push(date.toLocaleDateString().substring(0, 5));
        }

        if (row.price != undefined) {
          days_price.push(row.price);
          this.total_price_by_date += parseInt(row.price);
        }
      }

      this.data_by_day = {
        labels: days,
        datasets: [
          {
            label: "Сумма",
            backgroundColor: "#a058f3",
            data: days_price,
          },
        ],
      };
    },

    loadIncomePriceByConsoleList() {
      let list_by_console = this.getIncomePriceByConsoleList();

      let consoles = [];
      let consoles_price = [];
      for (let row of list_by_console) {
        if (row.console_number != undefined) {
          consoles.push(row.console_number);
        }

        if (row.price != undefined) {
          consoles_price.push(row.price);
        }
      }
      consoles_price.push(0);

      this.data_by_consoles = {
        labels: consoles,
        datasets: [
          {
            label: "Сумма",
            backgroundColor: "#3368FF",

            data: consoles_price,
          },
        ],
      };
    },

    loadIncomePriceByWeekDayList() {
      let list_by_weekday = this.getIncomePriceByWeekDayList();

      let weekday = [];
      let weekday_price = [];

      for (let row of list_by_weekday) {
        if (row.weekday != undefined) {
          weekday.push(row.weekday.substring(0, 3));
        }

        if (row.price != undefined) {
          weekday_price.push(row.price);
        }
      }

      this.data_by_weekday = {
        labels: weekday,
        datasets: [
          {
            label: "Сумма",
            backgroundColor: "#f17312",
            data: weekday_price,
          },
        ],
      };
    },

    loadRentBySource() {
      let list_by_source = this.getRentSourceStats();

      let source = [];
      let weekdaycount = [];

      for (let row of list_by_source) {
        if (row.source != undefined) {
          source.push(row.source);
        }

        if (row.count != undefined) {
          weekdaycount.push(row.count);
          this.total_rent_count += parseInt(row.count);
        }
      }

      this.data_by_source = {
        labels: source,
        datasets: [
          {
            label: "Кол-во",
            backgroundColor: "#33FF8D",
            data: weekdaycount,
          },
        ],
      };
    },

    async load() {
      this.overlay_visable = true;

      this.total_price_by_date = 0;
      this.total_rent_count = 0;
      let data = {
        begin_date: this.begin_date,
        end_date: this.end_date,
        rent_type: this.rent_type != "ВСЕ" ? this.rent_type : "",
      };

      await this.fetchGetIncomePriceByConsole(data);
      await this.fetchGetIncomePriceByWeekDay(data);
      await this.fetchGetIncomePriceByDay(data);
      await this.fetchGetRentSourceStats(data);
      await this.fetchGetRentStats(data);

      this.rent_stats = this.getRentStats();

      this.loadIncomePriceByDayList();
      this.loadIncomePriceByConsoleList();
      this.loadIncomePriceByWeekDayList();
      this.loadRentBySource();

      /*       const year_start = 2021;
      let list_result = [];
      this.value = [];

      for (var i = 0; i < 12; i++) {
        let data = this.getRequestDataByMonth(year_start, i);

        await this.fetchGetRentStats(data);

        let result = this.getRentStats();
        console.log(result['total_price']);

        if(result['total_price']!=null)
          this.value.push(result['total_price']);
      } */

      this.loaded = true;

      this.overlay_visable = false;
    },

    currentMonth() {
      let begin = new Date();
      begin.setDate(1);
      this.begin_date = begin.toISOString().substring(0, 10);

      let now = new Date();
      this.end_date = now.toISOString().substring(0, 10);
    },

    previousMonth() {
      let begin = new Date();
      begin.setDate(1);
      begin.setMonth(new Date().getMonth() - 1);
      this.begin_date = begin.toISOString().substring(0, 10);

      let end = new Date();
      end.setDate(0);

      this.end_date = end.toISOString().substring(0, 10);
    },

    getRequestDataByMonth(year, month_num) {
      let begin = new Date(year, month_num, 2);

      let end = new Date(year, month_num + 1);

      let data = {
        begin_date: begin.toISOString().substring(0, 10),
        end_date: end.toISOString().substring(0, 10),
        rent_type: this.rent_type != "ВСЕ" ? this.rent_type : "",
      };

      return data;
    },
  },

  mounted: function () {
    let begin = new Date();
    begin.setDate(1);
    this.begin_date = begin.toISOString().substring(0, 10);

    let now = new Date();
    this.end_date = now.toISOString().substring(0, 10);
  },
};
</script>

<style>
</style>