<template>
  <div>
    <v-overlay :value="overlay_visable">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col class="ml-auto">
        <v-btn
          small
          color="blue lighten-4"
          @click="downloadContract()"
          :disabled="downloadBtnDisabled"
          >Договор
          <v-icon small> mdi-file-download-outline </v-icon>
        </v-btn>
      </v-col>
      <v-col class="ml-auto">
        <v-btn
          small
          color="green lighten-4"
          @click="rentDeliveredOpenDialog()"
          :disabled="deliveredBtnDisabled"
          >Выдан
          <v-icon small>mdi-transfer-right</v-icon>
        </v-btn>
      </v-col>
      <v-col class="ml-auto">
        <v-btn
          small
          color="amber lighten-4"
          @click="rentDeliveredCancelOpenDialog()"
          :disabled="deliveredCancelBtnDisabled"
          >Отменить выдачу
          <v-icon small>mdi-transfer-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="ml-auto">
        <v-btn
          small
          color="green lighten-4"
          @click="rentCompleteOpenDialog()"
          :disabled="completedBtnDisabled"
          >Завершить <v-icon small>mdi-check-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col class="ml-auto">
        <v-btn
          small
          color="red lighten-3"
          @click="rentDeleteOpenDialog()"
          :disabled="deleteBtnDisabled"
          >Удалить <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title>
          {{ dialog_title }}
        </v-card-title>
        <v-card-text>{{ dialog_text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="action_confirmed = true"> Да </v-btn>
          <v-btn text @click="dialog = false"> Нет </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar_error"
      :timeout="snackbar_timeout"
      centered
      color="red darken-3"
      elevation="24"
      multi-line
    >
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar_error = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      dialog_title: "",
      dialog_text: "",
      deleted_call: false,
      completed_call: false,
      delivered_call: false,
      delivered_cancel_call: false,
      action_confirmed: false,
      snackbar_error: false,
      snackbar_text: "",
      snackbar_timeout: 1500,
      overlay_visable: false,
    };
  },

  watch: {
    dialog(newVal, val) {
      if (newVal == false && val) {
        this.deleted_call = false;
        this.completed_call = false;
        this.delivered_call = false;
        this.delivered_cancel_call = false;
      }
    },

    action_confirmed(confirmed) {
      if (!confirmed) return;

      if (this.completed_call) this.rentCompleteCall();

      if (this.deleted_call) this.rentDeleteCall();

      if (this.delivered_call) this.rentDeliveredCall();

      if (this.delivered_cancel_call) this.rentCancelDeliveredCall();
    },
  },

  methods: {
    ...mapGetters([
      "getDelivered",
      "getCompleted",
      "getProlongations",
      "getPersonId",
      "getRentId",
      "getRentObjLoaded",
      "getPersonObj",
      "getBlobContract",
    ]),

    ...mapActions([
      "fetchDeleteRent",
      "fetchCompletedRent",
      "fetchDeliveredRent",
      "fetchCancelDeliveredRent",
      "fetchDownloadContract",
    ]),

    rentDeliveredOpenDialog() {
      this.dialog = true;
      this.delivered_call = true;
      this.dialog_title = "Выдача";
      this.dialog_text = "Сменить статус на 'Выдан' ?";
    },

    rentDeliveredCancelOpenDialog() {
      this.dialog = true;
      this.delivered_cancel_call = true;
      this.dialog_title = "Отмена выдачи";
      this.dialog_text = "Отменить выдачу заказа ?";
    },

    rentCompleteOpenDialog() {
      this.dialog = true;
      this.completed_call = true;
      this.dialog_title = "Завершение";
      this.dialog_text = "Завершить аренду ?";
    },

    rentDeleteOpenDialog() {
      this.dialog = true;
      this.deleted_call = true;
      this.dialog_title = "Удаление";
      this.dialog_text = "Удалить аренду ?";
    },

    async downloadContract() {
      this.overlay_visable = true;

      try {
        await this.fetchDownloadContract(this.getRentId());

        let objURL = window.URL.createObjectURL(this.getBlobContract());

        console.log(objURL);

        var fileLink = document.createElement("a");
        fileLink.href = objURL;
        fileLink.download = this.getPersonObj().last_name + " Договор.docx";
        fileLink.click();

        URL.revokeObjectURL(objURL);
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }

      this.overlay_visable = false;
    },

    async rentDeliveredCall() {
      this.dialog = false;
      this.overlay_visable = true;
      try {
        console.log("Выдача заказа");
        await this.fetchDeliveredRent();
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }
      this.delivered_call = false;
      this.action_confirmed = false;
      this.overlay_visable = false;
    },

    async rentCancelDeliveredCall() {
      this.dialog = false;
      this.overlay_visable = true;
      try {
        console.log("Отмена выдачи заказа");
        await this.fetchCancelDeliveredRent();
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }
      this.delivered_cancel_call = false;
      this.action_confirmed = false;
      this.overlay_visable = false;
    },

    async rentDeleteCall() {
      this.dialog = false;
      this.overlay_visable = true;
      try {
        console.log("Удаление заказа");
        await this.fetchDeleteRent();
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }
      this.deleted_call = false;
      this.action_confirmed = false;
      this.overlay_visable = false;
    },

    async rentCompleteCall() {
      this.dialog = false;
      this.overlay_visable = true;
      try {
        console.log("Завершение заказа");
        await this.fetchCompletedRent();
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }
      this.completed_call = false;
      this.action_confirmed = false;
      this.overlay_visable = false;
    },
  },

  computed: {
    deleteBtnDisabled() {
      if (
        this.getDelivered() ||
        this.getCompleted() ||
        this.getRentId() === null
      )
        return true;
      else return false;
    },

    deliveredBtnDisabled() {
      if (
        this.getDelivered() ||
        this.getRentId() === null ||
        this.getRentObjLoaded() === null
      )
        return true;
      else return false;
    },

    deliveredCancelBtnDisabled() {
      if (
        this.getProlongations().length > 0 ||
        this.getRentId() === null ||
        !this.getDelivered() ||
        this.getRentObjLoaded() === null
      )
        return true;
      else return false;
    },

    completedBtnDisabled() {
      if (
        this.getRentObjLoaded() != null &&
        (this.getDelivered() || this.getCompleted())
      )
        return true;
      else return false;
    },

    downloadBtnDisabled() {
      if (this.getPersonId() === null || this.getRentId() === null) return true;
      else return false;
    },
  },
};
</script>

<style>
</style>