import {HorizontalBar, /*Doughnut, HorizontalBar,PolarArea,Bubble,Scatter*/ mixins} from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    extends: HorizontalBar,
    mixins: [reactiveProp],
    props: ['options'],
    mounted () {
        // this.chartData создаётся внутри миксина.
        // Если вы хотите передать опции, создайте локальный объект options
        this.renderChart(this.chartData, {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
        })
    }
}