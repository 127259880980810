<template>
  <div>
    <v-alert type="error"> Ошибка при загрузке данных </v-alert>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>