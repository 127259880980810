<template>
  <v-app id="inspire">
    <div>
      <v-app-bar
        color="primary"
        dense
        dark
      >

  
        <v-toolbar-title>
          <v-btn href="/" text>
        <span class="mr-2">Главная</span>
      </v-btn>
          <v-btn href="/consoles" text>
        <span class="mr-2">Консоли</span>
      </v-btn>
              <v-btn icon :href="'/reports'">
          <v-icon>mdi-chart-box</v-icon>
        </v-btn>
      </v-toolbar-title>
  
        <v-spacer></v-spacer>



  
<!--         <v-menu
          left
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
  
          <v-list>
            <v-list-item
              v-for="n in 5"
              :key="n"
              @click="() => {}"
            >
              <v-list-item-title>Option {{ n }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-app-bar>
    </div>
        <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import {getCookieIsAuth} from "./conf";

export default {
  name: "App",

  data: () => ({
    isAuth: false
  }),

  mounted: function(){

        this.isAuth = getCookieIsAuth();
  }
};
</script>
