<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :loading="loading"
        :color="color_btn"
        @click="dialog = true"
        dark
        x-small
        style="margin-left: 10px"
        v-bind="attrs"
        v-on="on"
      >
        {{ button_text }}
       <template>
         <DrgDialog
            ref="drg_dialog_form"
            v-on:closeEvent="dialog = false"
          />
        </template> 
      </v-btn>
    </template>
    <span>{{ tooltip_text }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DrgDialog from "../Dialogs/DrgDialog.vue";

export default {
  data() {
    return {
      loading: false,
      error: false,
      color_btn: "primary",
      button_text: "дрг",
      drg_result: null,
      dialog : false,
    };
  },

  components : {

      DrgDialog
  },

    watch: {
    dialog(val) {
      if (val) this.$refs.drg_dialog_form.show();
    },
  },

  computed: {
    tooltip_text() {
      let text = "";

      if (this.fssp_error) {
        text = "Ошибка при проверке данных по дрг !";
      } else if (this.drg_result!=null) {
        text = "найдено в дрг";
      } else if (this.drg_result === null) {
        text = "в дрг нет";
      }

      return text;
    },
  },

  methods: {
    ...mapActions(["fetchCheckDrg"]),
    ...mapGetters(["getDrg", "getSelectedPersonForRent"]),

    async drgCheck() {
      if (this.getSelectedPersonForRent() === null) {
        this.color_btn = "primary";
        return;
      }

      this.loading = true;
      this.color_btn = "blue-grey lighten-3";

      try {
        await this.fetchCheckDrg({
          first_name: this.getSelectedPersonForRent().first_name,
          last_name: this.getSelectedPersonForRent().last_name,
          middle_name: this.getSelectedPersonForRent().middle_name,
          b_day: this.getSelectedPersonForRent().b_day,
        });

        this.drg_result = this.getDrg();

        if (this.drg_result === null) {
          this.color_btn = "green darken-2";
          this.button_text = "Дрг";
        } else if (this.drg_result != null) {
          this.color_btn = "deep-orange darken-2";
          this.button_text = "ДРГ!";
        }
      } catch {
        this.fssp_error = true;
        this.color_btn = "error";
        this.button_text = "Дрг ошибка";
      }

      this.loading = false;
    },
  },
};
</script>

<style>
</style>
