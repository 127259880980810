<template>
  <div>
    <div>
      <v-overlay :value="overlay_visable">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <div>
        <v-snackbar v-model="snackbar" :timeout="timeout" top color="error">
          {{ save_error_text }}

          <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="snackbar = false">
              Закрыть
            </v-btn>
          </template>
        </v-snackbar>
      </div>

      <div v-if="loaded" style="border-top-width: 10px; margin-top: 50px">
        <PersonName ref="personNameRef" />
        <PersonPassport ref="personPassportRef" />

        <div v-if="person_id === null">
          <v-row justify="center">
            <v-btn color="success" v-on:click="save">Сохранить</v-btn>
          </v-row>
        </div>
        <div v-if="person_id != null">
          <v-row justify="center">
            <v-col cols="4" md="2">
              <v-btn color="success" v-on:click="save">Сохранить</v-btn>
            </v-col>

            <v-col cols="4" md="2">
              <div class="text-center">
                <v-dialog v-model="dialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      :disabled="person_id === undefined || person_id === null"
                    >
                      Удалить
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                      Удалить клиента ?
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>
                      <v-btn color="success" text @click="remove"> Да </v-btn>
                      <v-btn color="error" text @click="dialog = false">
                        Нет
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <Error
      v-if="error_loading"
      style="border-top-width: 10px; margin-top: 50px"
    />
  </div>
</template>

<script>
import PersonName from "./InputName.vue";
import PersonPassport from "./InputPassport.vue";
import Error from "../Error.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PersonName,
    PersonPassport,
    Error,
  },
  data() {
    return {
      overlay_visable: false,
      loaded: false,
      error_loading: false,
      error_save: false,
      save_error_text: "",
      snackbar: false,
      timeout: 3000,
      dialog: false,
      person_id: this.id,
    };
  },
  props: ["id"],
  mounted: async function () {
    this.load();
  },

  methods: {
    async load() {
      if (this.id != undefined && this.id != null) {
        try {
          this.overlay_visable = true;
          await this.fetchGetPersonById(this.id);

          this.loaded = true;
          this.error_loading = false;
        } catch {
          this.error_loading = true;
        } finally {
          this.overlay_visable = false;
        }
      } else {
        this.clearPersonData();
        this.loaded = true;
        this.error_loading = false;
      }
    },

    ...mapActions([
      "fetchGetPersonById",
      "fetchPersonSave",
      "fetchDeletePerson",
      "clearPersonData",
    ]),
    ...mapGetters([
      "getNameFieldsIsValid",
      "getPassportFieldsIsValid",
      "getId",
    ]),

    async save() {
      let validPassport = this.getPassportFieldsIsValid();
      let validName = this.getNameFieldsIsValid();

      this.$refs.personPassportRef.validate();
      this.$refs.personNameRef.validate();

      if (!validPassport || !validName) return;

      try {
        this.overlay_visable = true;
        await this.fetchPersonSave();

        if (this.person_id === undefined || this.person_id === null)
          this.person_id = this.getId();

        this.overlay_visable = false;
        this.$emit("savePersonCompleteEvent", this.person_id);
      } catch (e) {
        this.error_save = true;
        this.save_error_text = e.message;
        this.snackbar = true;
        this.overlay_visable = false;
      }
    },

    async remove() {
      this.dialog = false;
      try {
        this.overlay_visable = true;
        await this.fetchDeletePerson();
        window.location.href = "/persons";
      } catch (e) {
        this.error_save = true;
        this.save_error_text = e.message;
        this.snackbar = true;
      } finally {
        this.overlay_visable = false;
      }
    },
  },
};
</script>

<style>
</style>