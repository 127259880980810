<template>
  <div class="text-center" v-if="fssp_items.length > 0">
    <v-dialog v-model="dialog" width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{
            fssp_items[0].name +
            " | ~ " +
            fssp_total_sum.toLocaleString() +
            " руб."
          }}
        </v-card-title>

        <v-list-item
          v-for="(item, index) in fssp_items"
          :key="index"
          three-line
        >
          <v-list-item-content>
            <v-list-item-title style="font-weight: bold">{{
              item.exe_production
            }}</v-list-item-title>
            <span>{{ item.subject }}</span>
            <span>{{ item.details }}</span>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      fssp_items: [],
      fssp_total_sum: 0,
      dialog: false,
    };
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.$emit("closeEvent");
      }
    },
  },

  methods: {
    ...mapGetters(["getFsspItems", "getFsspTotalSum", "getSelectedPersonForRent"]),

    show() {
      if (this.getSelectedPersonForRent() === null) return;

      this.dialog = true;
      this.fssp_items = this.getFsspItems();
      this.fssp_total_sum = this.getFsspTotalSum();
    },
  },
};
</script>

<style>
</style>