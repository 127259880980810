<template>
  <div class="text-center" v-if="rents.length > 0">
    <v-dialog v-model="dialog" width="550">
      <v-card>
        <div>
          <v-card-title v-if="rents.length > 0"
            >Всего аренд : {{ rents.length }} на сумму {{ getTotalSum }} р.
          </v-card-title>
          <v-list-item
            v-for="(item, index) in getRentPage"
            :key="index"
            two-line
          >
            <v-list-item-content>
              <v-list-item-title
                >с
                {{
                  new Date(item.periods[0].begin_date)
                    .toLocaleString("ru", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      timezone: "UTC",
                    })
                    .substring(0, 10)
                }}
                по
                {{
                  new Date(item.periods[item.periods.length - 1].end_date)
                    .toLocaleString("ru", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      timezone: "UTC",
                    })
                    .substring(0, 10)
                }}
                ({{ item.day_amount }} дн.)
                {{ item.console.console_type.code }} №
                {{ item.console.number }}
                сумма {{ item.total_price }} р.
                <v-btn
                  icon
                  small
                  :href="'/rent/' + item.id"
                  color="deep-purple lighten-1"
                >
                  <v-icon>mdi-arrow-right-thin-circle-outline</v-icon></v-btn
                >
                <v-textarea
                  v-if="item.description != ''"
                  outlined
                  label="Комментарий"
                  rows="2"
                  row-height="15"
                  :value="item.description"
                  readonly
                  style="margin-top: 10px"
                ></v-textarea>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-pagination v-model="page" :length="getPageCount"></v-pagination>
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      rents: [],
      page: 1,
      rows_on_page: 3,
    };
  },

  computed: {
    getRentPage() {
      const size = this.rows_on_page;
      let output = [];

      let start = 0;
      let end = 0;

      if (this.page === 1) {
        end = size - 1;

        if (end > this.rents.length) end = this.rents.length - 1;
      } else {
        start = (this.page - 1) * size;

        if (start + size - 1 <= this.rents.length - 1) end = start + size - 1;
        else end = this.rents.length - 1;
      }

      for (let i = start; i <= end; i++) output.push(this.rents[i]);

      return this.rents;
    },

    getPageCount() {
      return Math.ceil(this.rents.length / this.rows_on_page);
    },

    getTotalSum() {
      let total = 0;

      for (let rent of this.rents) {
        total += rent.total_price;
      }

      return total.toLocaleString();
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        console.log('close rent history');
        this.$emit("closeEvent");
      }
    },
  },

  methods: {
    ...mapGetters(["getOrderHistoryByPerson", "getSelectedPersonForRent"]),

    show() {
    

      this.dialog = true;
      this.rents = this.getOrderHistoryByPerson();
      console.log(this.rents);
      this.page = 1;
    },
  },
};
</script>

<style>
</style>