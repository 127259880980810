import { apiUrl, getCookieToken, setCookie } from "../../conf";

export default {

    actions: {

        async fetchGetConsoleById({commit}, id) {

            const result = await fetch(apiUrl + '/api/consoles/' + id, {

                headers: {

                    Token: getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const item = await result.json()

                commit('updateConsole', item)
            }
        },

        async fetchGetViewConsoleByIdV2({commit}, id) {

            const result = await fetch(apiUrl + '/api/v2/console/' + id + '/view', {

                headers: {

                    Token: getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const item = await result.json()

                commit('updateConsole', item)
            }
        },

    },

    mutations: {

        updateConsole(state, _game_console){

            state.game_console = _game_console
        },

    },

    state: {

        game_console: null,
        
    },

    getters: {

        getConsoleById(state){

            return state.game_console;
        },
    }
}