<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="10" lg="10">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="series"
                  label="Серия паспорта"
                  required
                  @keypress="keyPressSeries"
                  :rules="rulesSeries"
                  id="input-passport-sereis"
                  @keydown="keyDownSeries"
                  @keyup="keyUpSeries"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="number"
                  label="Номер паспорта"
                  required
                  @keypress="keyPressNumber"
                  :rules="rulesNumber"
                  id="input-passport-number"
                  @keydown="keyDownNumber"
                  @keyup="keyUpNumber"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="issued_date"
                  label="Дата выдачи паспорта"
                  required
                  type="date"
                  :rules="rulesIssuedDate"
                  id="input-passport-date"
                  @keydown="keyDownIssuedDay"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-autocomplete
                  v-model="issued_by"
                  :loading="loading_issued_by"
                  :items="items_issued_by"
                  :search-input.sync="search_issued_by"
                  flat
                  hide-no-data
                  hide-details
                  label="Кем выдан паспорт"
                  id="input-issued-by"
                  clearable
                  required
                  :rules="rulesIssuedBy"
                  @input="inputIssuedBy"
                ></v-autocomplete> </v-col
            ></v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-autocomplete
                  v-model="offical_address"
                  :loading="loading_official_address"
                  :items="items_official_address"
                  :search-input.sync="search_official_address"
                  flat
                  hide-no-data
                  hide-details
                  label="Адрес прописки"
                  id="input-official-address"
                  clearable
                  no-filter
                  required
                  :rules="rulesOficialAddress"
                  @input="inputOfficialAddress"
                ></v-autocomplete>
                <v-btn
                  v-if="
                    offical_address != '' &&
                    (offical_address != null) & !address_error
                  "
                  x-small
                  color="success"
                  dark
                  style="margin-top: 10px"
                  v-on:click="doubleGisOpen"
                  >2 GIS
                  <v-icon dark small>mdi-map-search</v-icon>
                </v-btn>
                <v-btn
                  v-if="
                    offical_address != '' &&
                    (offical_address != null) & !address_error
                  "
                  x-small
                  color="warning"
                  dark
                  style="margin-top: 10px; margin-left: 10px"
                  v-on:click="googleMapsOpen"
                  >Google maps
                  <v-icon dark small>mdi-map-search</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    valid: true,
    number: "",
    series: "",
    issued_date: "",
    issued_by: "",
    offical_address: "",

    loading_issued_by: false,
    items_issued_by: [],
    search_issued_by: "",

    loading_official_address: false,
    items_official_address: [],
    search_official_address: "",
    address_error: false,

    series_len: 4,
    number_len: 6,
    enter_key_code: 13,
  }),

  created: function () {
    this.number = this.getPassportNumber();
    this.series = this.getPassportSeries();
    this.issued_date = this.getPassportDateOfIssue();

    this.issued_by = this.getPassportIssuedBy();

    if (this.getPassportIssuedBy() != null && this.getPassportIssuedBy() != "")
      this.items_issued_by.push(this.getPassportIssuedBy());

    this.offical_address = this.getOfficialAddress();

    if (this.getOfficialAddress() != null && this.getOfficialAddress() != "")
      this.items_official_address.push(this.getOfficialAddress());
  },

  computed: {
    rulesSeries() {
      const rules = [];

      if (!this.getSeriesIsValid()) {
        const rule = (v) =>
          (v || "").length === this.series_len ||
          `${this.series_len} символа !`;

        rules.push(rule);
      }

      return rules;
    },

    rulesNumber() {
      const rules = [];

      if (!this.getNumberIsValid()) {
        const rule = (v) =>
          (v || "").length === this.number_len ||
          `${this.number_len} символов!`;

        rules.push(rule);
      }

      return rules;
    },

    rulesIssuedBy() {
      const rules = [];

      if (!this.getIssuedByIsValid()) {
        const rule = (v) => (v || "").length > 0 || `Заполните поле`;
        rules.push(rule);
      }

      return rules;
    },

    rulesIssuedDate() {
      const rules = [];
      const DATE_1_JAN_1900 = -2208988800000;

      if (!this.getIssuedDateIsValid()) {
        rules.push((v) => !isNaN(Date.parse(v)) || `Заполните поле`);
        rules.push(
          (v) => Date.parse(v) >= DATE_1_JAN_1900 || `Введите корректную дату`
        );
      }

      return rules;
    },

    rulesOficialAddress() {
      const rules = [];

      if (!this.getOfficialAddressIsValid()) {
        const rule = (v) => (v || "").length > 0 || `Заполните поле`;
        rules.push(rule);
      }

      return rules;
    },
  },

  watch: {
    search_issued_by(val) {
      val && val !== this.issued_by && this.searchFms(val);
    },

    search_official_address(val) {
      val && val !== this.offical_address && this.searchAddress(val);
    },

    number(newVal, oldVal) {
      if (newVal != oldVal) this.setPassportNumber(this.number);
    },

    series(newVal, oldVal) {
      if (newVal != oldVal) this.setPassportSeries(this.series);
    },

    issued_date(newVal, oldVal) {
      if (newVal != oldVal) this.setPassportDateOfIssue(this.issued_date);
    },

    issued_by(newVal, oldVal) {
      if (newVal != oldVal) this.setPassportIssuedBy(this.issued_by);
    },

    offical_address(newVal, oldVal) {
      if (newVal != oldVal) this.setOfficialAddress(this.offical_address);
    },
  },

  methods: {
    ...mapGetters([
      "getPassportSeries",
      "getPassportNumber",
      "getPassportIssuedBy",
      "getPassportDateOfIssue",
      "getOfficialAddress",
      "getSeriesIsValid",
      "getNumberIsValid",
      "getIssuedDateIsValid",
      "getIssuedByIsValid",
      "getOfficialAddressIsValid",
      "getFmsList",
      "getDataAddress",
      "getPassportFieldsIsValid",
      "getRegionName"
    ]),

    ...mapMutations([
      "setPassportSeries",
      "setPassportNumber",
      "setPassportIssuedBy",
      "setPassportDateOfIssue",
      "setOfficialAddress",
    ]),

    ...mapActions(["fetchSuggestFms", "fetchSuggestAddress"]),

    validate() {
      this.$refs.form.validate();
      return this.valid;
    },

    //KEY EVENTS

    //----------------------------------   Series --------------------------------------------------//
    keyPressSeries($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      if (
        ((keyCode < 48 || keyCode > 57) && keyCode !== 46) ||
        (this.series != null && this.series.length === this.series_len)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    keyUpSeries() {
      if (this.getSeriesIsValid() && this.series != null && this.series!="") {
        document.getElementById("input-passport-number").focus();
      }
    },

    keyDownSeries($event) {
      if ($event.keyCode === this.enter_key_code && this.getSeriesIsValid() && this.series != null && this.series!="" ) {
        document.getElementById("input-passport-number").focus();
      }
    },
    //----------------------------------   Series --------------------------------------------------//

    //---------------------------------- Number --------------------------------------------------//
    keyPressNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      if (
        ((keyCode < 48 || keyCode > 57) && keyCode !== 46) ||
        (this.number != null && this.number.length === this.number_len)
      ) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    keyDownNumber($event) {
      if ($event.keyCode === this.enter_key_code && this.getNumberIsValid()) {
        document.getElementById("input-passport-date").focus();
      }
    },

    keyUpNumber() {
      if (this.getNumberIsValid() && this.number != "")
        document.getElementById("input-passport-date").focus();
    },

    //---------------------------------- Number --------------------------------------------------//

    keyDownIssuedDay($event) {
      const DATE_1_JAN_1900 = -2208988800000;

      if ($event.keyCode === this.enter_key_code) {
        let valid = null;

        if (
          isNaN(Date.parse(this.issued_date)) ||
          Date.parse(this.issued_date) < DATE_1_JAN_1900 ||
          Date.now() <= Date.parse(this.issued_date)
        )
          valid = false;
        else valid = true;

        if (valid) document.getElementById("input-issued-by").focus();
      }
    },

    inputIssuedBy() {
      document.getElementById("input-official-address").focus();
    },

    inputOfficialAddress() {},

    //KEY EVENTS

    doubleGisOpen() {
      window.open("https://2gis.ru/lipetsk/search/" + this.offical_address);
    },

    googleMapsOpen() {
      window.open("https://www.google.com/maps/place/" + this.offical_address);
    },

    async searchFms(search) {
      if (search === "" || search === null) return;

      this.items_issued_by = [];
      this.loading_issued_by = true;

      await this.fetchSuggestFms(search);
      let list_data = this.getFmsList();

      for (let data of list_data.suggestions) {
        this.items_issued_by.push(data.value);
      }

      if (list_data.suggestions.length === 0) this.items_issued_by.push(search);

      this.loading_issued_by = false;
    },

    async searchAddress(_search) {
      if (_search === "") return;

      this.items_official_address = [];
      this.loading_official_address = true;


      await this.fetchSuggestAddress(_search);

      let list_data = this.getDataAddress();

      for (let data of list_data)
        this.items_official_address.push(data.unrestricted_value);

      if (list_data.length === 0) this.items_official_address.push(_search);

      this.loading_official_address = false;
    },
  },
};
</script>