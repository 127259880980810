import { apiUrl, getCookieToken, setCookie } from "../../conf";

export default {

    actions: {

        async fetchGetAllGamePad(ctx) {

            const result = await fetch(apiUrl + '/api/gamepads', {

                headers: {

                    Token: getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const list = await result.json()

                ctx.commit('updateGamePadList', list)
            }
        },

        async fetchGetGamePad(ctx, id) {

            const result = await fetch(apiUrl + '/api/gamepads/' + id, {

                headers: {

                    Token: getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const list = await result.json()

                ctx.commit('updateGamePad', list)
            }
        },

        async fetchAddGamepad({ commit }, gamepad) {


            const result = await fetch(apiUrl + '/api/gamepads', {

                method: 'POST',
                body: JSON.stringify(gamepad),
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                let json = await result.json()

                gamepad.id = json.id;

                commit('updateGamePad', gamepad)
            }

        },


    },

    state: {

        gamepad_list: [],
        gamepad: null,
    },

    mutations: {

        updateGamePadList(state, items) {

            state.gamepad_list = items;
        },

        updateGamePad(state, item) {

            state.gamepad = item;
        }

    },

    getters: {

        getGamePadList(state) {

            return state.gamepad_list;
        },

        getGamepad(state) {

            return state.gamepad;
        }
    }

}