import { apiUrl, getDadataConfig } from "../conf";

export default {

    actions: {

        async fetchSuggestFio({ commit }, query) {

            const result = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio', {

                method: 'POST',
                body: JSON.stringify({ query: query }),
                headers: {
                    "Content-Type": "application/json",
                    "X-Secret": getDadataConfig().secretDadata,
                    "Authorization": getDadataConfig().tokenDadata
                },
            });

            if (result.ok) {

                let json = await result.json();

                commit('updateFioList', json)
            }
        },


        async fetchSuggestAddress({ commit }, query) {

            if(getDadataConfig().regionName!=null && getDadataConfig().regionName!= "")
                query = getDadataConfig().regionName + ", " + query;
            
            const result = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {

                method: 'POST',
                body: JSON.stringify({ query: query }),
                headers: {
                    "Content-Type": "application/json",
                    "X-Secret": getDadataConfig().secretDadata,
                    "Authorization": getDadataConfig().tokenDadata
                },
            });

            if (result.ok) {

                let json = await result.json();

                commit('updateAddressList', json)
            }
        },

        async fetchPassportIsValid({ commit }, query) {

            const result = await fetch(apiUrl + '/api/passport_check/' + query, {

                method: 'GET',
                mode: "cors",
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    "X-Secret": getDadataConfig().secretDadata,
                    "Authorization": getDadataConfig().tokenDadata
                },
            });

            if (result.ok) {

                let json = await result.json();

                commit('updatePassportValid', json[0])
            }
        },

        async fetchSuggestFms({ commit }, query_text) {

            const query = {

                query: query_text,
                filters: [{

                    'region_code': getDadataConfig().regionCode
                }]
            };

            const result = await fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit', {

                method: 'POST',
                body: JSON.stringify(query),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getDadataConfig().tokenDadata
                },
            });

            if (result.ok) {

                let json = await result.json();

                commit('updateFmsList', json)
            }
        },

    },

    mutations: {

        updateFioList(state, list) {

            state.fio_list = list.suggestions;
        },

        updateAddressList(state, list) {

            state.address_list = list.suggestions;
        },

        updatePassportValid(state, item) {

            if (item.qc === 0) {

                state.passport_is_valid = true;
            }
            else {

                state.passport_is_valid = false;
            }
        },

        updateFmsList(state, list) {

            state.fms_list = list;
        }


    },

    state: {

        fio_list: [],
        address_list: [],
        passport_is_valid: null,
        fms_list: []

    },



    getters: {

        getDataFio(state) {

            return state.fio_list;
        },

        getDataAddress(state) {

            return state.address_list;
        },

        getPassportIsValid(state) {

            return state.passport_is_valid;
        },

        getFmsList(state) {

            return state.fms_list;
        }
    },


}