<template>
  <v-btn
    :loading="loading_passport"
    :color="color_passport_btn"
    x-small
    dark
    style="margin-left: 10px"
  >
    {{ text_passport_btn }}
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading_passport: false,
      color_passport_btn: "primary",
      text_passport_btn: "Проверка паспорта",
      loader: null,
    };
  },

  methods: {
    ...mapGetters(["getSelectedPersonForRent", "getPassportIsValid"]),
    ...mapActions(["fetchPassportIsValid"]),

    async passportCheck() {
      if (this.getSelectedPersonForRent() === null) {
        this.color_passport_btn = "primary";
        this.text_passport_btn = "Проверка паспорта";
        return;
      }

      this.loading_passport = true;
      this.color_passport_btn = "blue-grey lighten-3";

      try {
        await this.fetchPassportIsValid(
          this.getSelectedPersonForRent().p_series +
            " " +
            this.getSelectedPersonForRent().p_number
        );

        this.passport_is_valid = this.getPassportIsValid();

        if (this.passport_is_valid) {
          this.color_passport_btn = "green darken-2";
          this.text_passport_btn = "Паспорт действителен";
        } else {
          this.color_passport_btn = "deep-orange darken-2";
          this.text_passport_btn = "Паспорт недействителен!";
        }
      } catch {
        this.text_passport_btn = "Паспорт ошибка!";
        this.color_passport_btn = "red darken-4";
      }

      this.loading_passport = false;
    },
  },
};
</script>

<style>
</style>