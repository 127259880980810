import { apiUrl, getCookieToken, setCookie } from "../conf";

export default {

    actions: {

        async fetchGetPersonById({commit}, id) {

            const result = await fetch(apiUrl + '/api/persons/' + id, {

                headers: {

                    Token: getCookieToken()
                }
            });



            if (result.status === 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const person = await result.json()

                commit('setId', person.id);
                commit('setFirstName', person.first_name);
                commit('setLastName', person.last_name);
                commit('setMiddleName', person.middle_name);
                commit('setBday', person.b_day);
                commit('setPassportSeries', person.p_series);
                commit('setPassportNumber', person.p_number);
                commit('setPassportIssuedBy', person.p_issued_by);
                commit('setPassportDateOfIssue', person.p_date_of_issue);
                commit('setOfficialAddress', person.p_official_address);
                commit('setPhoneNumber', person.phone_number);
                commit('setSocialUrl', person.social_url);
            }
        },

        async fetchPersonSave({ commit, getters }) {

            let result = null;

            if (getters.getId === null) {
                result = await fetch(apiUrl + '/api/persons', {

                    method: 'POST',
                    body: JSON.stringify(getters.getPersonObj),
                    headers: { "Content-Type": "application/json", Token: getCookieToken() },
                });
            }
            else {

                result = await fetch(apiUrl + '/api/persons/' + getters.getId, {

                    method: 'PUT',
                    body: JSON.stringify(getters.getPersonObj),
                    headers: { "Content-Type": "application/json", Token: getCookieToken() },
                });
            }

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }
            else if (result.status === 200 || result.status === 201) {

                let json = await result.json()

                commit('setId', json.id)
            }
            else if (!(result.status === 204 || result.status === 201 || result.status === 200)) {

                throw new Error(await result.text())
            }


        },

        async fetchDeletePerson({ getters }) {

            if (getters.getId === null) return;

            const result = await fetch(apiUrl + '/api/persons/' + getters.getId, {

                method: 'DELETE',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status != 204) {

                console.log('status delete ' + result.status);
                throw new Error(await result.text())
            }
        },

        clearPersonData({commit}) {

            commit('setId', null);
            commit('setFirstName', null);
            commit('setLastName', null);
            commit('setMiddleName', null);
            commit('setBday', null);
            commit('setPassportSeries', null);
            commit('setPassportNumber', null);
            commit('setPassportIssuedBy', null);
            commit('setPassportDateOfIssue', null);
            commit('setOfficialAddress', null);
            commit('setPhoneNumber', null);
            commit('setSocialUrl', null);
        }

    },

    mutations: {

        setId(state, id) {

            state.id = id;
        },

        setFirstName(state, value) {

            state.first_name = value;
        },

        setLastName(state, value) {

            state.last_name = value;
        },

        setMiddleName(state, value) {

            state.middle_name = value;
        },

        setBday(state, value) {

            state.b_day = value;
        },

        setPassportSeries(state, value) {

            state.p_series = value;
        },

        setPassportNumber(state, value) {

            state.p_number = value;
        },

        setPassportIssuedBy(state, value) {

            state.p_issued_by = value;
        },

        setPassportDateOfIssue(state, value) {

            state.p_date_of_issue = value;
        },

        setOfficialAddress(state, value) {

            state.p_official_address = value;
        },

        setPhoneNumber(state, value) {

            state.phone_number = value;
        },

        setSocialUrl(state, value) {

            state.social_url = value;
        },

        setDeleted(state, value) {

            state.deleted = value;
        }
    },

    state: {

        id: null,
        first_name: "",
        last_name: "",
        middle_name: "",
        b_day: null,
        p_series: "",
        p_number: "",
        p_issued_by: "",
        p_date_of_issue: null,
        p_official_address: "",
        residence_address: "",
        phone_number: "",
        social_url: "",
        deleted: false,
    },

    getters: {

        getPersonObj(state) {

            return {
                id: state.id,
                first_name: state.first_name,
                last_name: state.last_name,
                middle_name: state.middle_name,
                b_day: state.b_day,
                p_series: state.p_series,
                p_number: state.p_number,
                p_issued_by: state.p_issued_by,
                p_date_of_issue: state.p_date_of_issue,
                p_official_address: state.p_official_address,
                residence_address: state.residence_address,
                phone_number: state.phone_number,
                social_url: state.social_url,
            }
        },

        getId(state) {

            return state.id;
        },

        getFirstName(state) {

            return state.first_name;
        },

        getLastName(state) {

            return state.last_name;
        },

        getMiddleName(state) {

            return state.middle_name;
        },

        getBday(state) {

            return state.b_day;
        },

        getPassportSeries(state) {

            return state.p_series;
        },

        getPassportNumber(state) {

            return state.p_number;
        },

        getPassportIssuedBy(state) {

            return state.p_issued_by;
        },

        getPassportDateOfIssue(state) {

            return state.p_date_of_issue;
        },

        getOfficialAddress(state) {

            return state.p_official_address;
        },

        getPhoneNumber(state) {

            return state.phone_number;
        },

        getSocialUrl(state) {

            return state.social_url;
        },

        getDeleted(state) {

            return state.deleted;
        },

        //----------------------------  Валидация   --------------------------------------------------------------------/
        getFirstNameIsValid(state) {
            let is_valid = true

            if (state.first_name === undefined || state.first_name === null || state.first_name === "")
                is_valid = false;

            return is_valid;
        },

        getMiddleNameIsValid(state) {

            let is_valid = true;

            if (
                state.b_day != null &&
                state.first_name != "" &&
                state.last_name != "" &&
                state.middle_name === ""
            ) {
                is_valid = false;
            } else if (
                state.b_day != null &&
                state.first_name != "" &&
                state.last_name != "" &&
                state.middle_name != ""
            ) {
                is_valid = true;
            } else is_valid = true;

            return is_valid;
        },

        getLastNameIsValid(state) {
            let is_valid = true

            if (state.last_name === undefined || state.last_name === null || state.last_name === "")
                is_valid = false;

            return is_valid;
        },

        getBdayIsValid(state) {
            let is_valid = false;

            if (
                state.middle_name === "" &&
                (state.b_day === null || state.b_day === "")
            ) {
                is_valid = true;
            } else if (
                state.first_name != "" &&
                state.last_name != "" &&
                state.middle_name != "" &&
                (state.b_day === null || state.b_day === "")
            ) {
                is_valid = false;
            } else {
                is_valid = true;
            }

            return is_valid;
        },

        getSeriesIsValid(state) {
            const SERIES_LEN = 4;
            var pattern = new RegExp("^[0-9]*$");

            let isDigits = pattern.test(state.p_series);

            let valid_result = true;
            let valid =
                isDigits && state.p_series.length === SERIES_LEN ? true : false;

            let numberIsEmpty =
                state.p_number === "" || state.p_number === null ? true : false;

            let seriesIsEmpty =
                state.p_series === "" || state.p_series === null ? true : false;

            if (seriesIsEmpty && numberIsEmpty) valid_result = true;
            else if (valid) valid_result = true;
            else valid_result = false;

            return valid_result;
        },

        getNumberIsValid(state) {
            const NUM_LEN = 6;
            var pattern = new RegExp("^[0-9]*$");

            let isDigits = pattern.test(state.p_number);

            let valid_result = true;
            let valid =
                isDigits && state.p_number.length === NUM_LEN ? true : false;

            let numberIsEmpty =
                state.p_number === "" || state.p_number === null ? true : false;

            let seriesIsEmpty =
                state.p_series === "" || state.p_series === null ? true : false;

            if (seriesIsEmpty && numberIsEmpty) valid_result = true;
            else if (valid) valid_result = true;
            else valid_result = false;

            return valid_result;
        },

        getIssuedDateIsValid(state) {
            const DATE_1_JAN_1900 = -2208988800000;

            let valid = null;
            let issued_date_valid = true;

            if (
                isNaN(Date.parse(state.p_date_of_issue)) ||
                Date.parse(state.p_date_of_issue) < DATE_1_JAN_1900 ||
                Date.now() < Date.parse(state.p_date_of_issue)
            )
                valid = false;
            else valid = true;

            let dateisEmpty = isNaN(Date.parse(state.p_date_of_issue));

            let numberIsEmpty =
                state.p_number === "" || state.p_number === null ? true : false;

            let seriesIsEmpty =
                state.p_series === "" || state.p_series === null ? true : false;

            if (dateisEmpty && numberIsEmpty && seriesIsEmpty)
                issued_date_valid = true;
            else if (valid) issued_date_valid = true;
            else issued_date_valid = false;

            return issued_date_valid;
        },

        getIssuedByIsValid(state) {
            let issuedByEmpty =
                state.p_issued_by === null || state.p_issued_by === ""
                    ? true
                    : false;

            let issued_by_valid = true;

            let numberIsEmpty =
                state.p_number === "" || state.p_number === null ? true : false;

            let seriesIsEmpty =
                state.p_series === "" || state.p_series === null ? true : false;

            if (seriesIsEmpty && numberIsEmpty && issuedByEmpty)
                issued_by_valid = true;
            else if (!issuedByEmpty) issued_by_valid = true;
            else issued_by_valid = false;

            return issued_by_valid;
        },

        getOfficialAddressIsValid(state, getters) {

            let official_address_valid = false;

            let numberIsEmpty =
                state.p_number === "" || state.p_number === null ? true : false;

            let seriesIsEmpty =
                state.p_series === "" || state.p_series === null ? true : false;


            if (!seriesIsEmpty && getters.getSeriesIsValid && !numberIsEmpty && getters.getNumberIsValid &&
                (state.p_official_address === null || state.p_official_address === ''))
                official_address_valid = false;
            if ((!seriesIsEmpty || !numberIsEmpty) && (state.p_official_address === null || state.p_official_address === ''))
                official_address_valid = false;
            else
                official_address_valid = true;

            return official_address_valid;
        },

        getPhoneIsValid(state) {
            const PHONE_LENGTH = 11;

            let isValid = false;

            var pattern = new RegExp("^[0-9]*$");

            if (
                state.phone_number != null &&
                state.phone_number != "" &&
                pattern.test(state.phone_number) &&
                state.phone_number.length === PHONE_LENGTH
            ) {
                isValid = true;
            }

            return isValid;
        },

        getNameFieldsIsValid(state, getters) {

            if (getters.getFirstNameIsValid && getters.getLastNameIsValid && getters.getMiddleNameIsValid && getters.getBdayIsValid
                && getters.getPhoneIsValid)
                return true;
            else
                return false;
        },

        getPassportFieldsIsValid(state, getters) {

            if (getters.getNumberIsValid && getters.getSeriesIsValid && getters.getIssuedByIsValid && getters.getIssuedDateIsValid && getters.getOfficialAddressIsValid)
                return true;
            else
                return false;

        }

    }
}