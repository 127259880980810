import { apiUrl, getCookieToken, setCookie } from "../../conf";

export default {

    actions: {

        async fetchGetGameConsole({ commit }, id) {

            const result = await fetch(apiUrl + '/api/v2/console/' + id , {

                headers: {

                    Token: getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const item = await result.json()

                commit('updateConsole', item)
                commit('updateGamesForConsole', item.games);
                commit('updateGamePadsForConsole', item.gamepads);

            }
        },

        async fetchSaveGameConsole({ commit, getters }) {

            let result = null;

            if (getters.getSaveObj.id != null) {
                result = await fetch(apiUrl + '/api/v2/console/' + getters.getSaveObj.id , {

                    method: 'PUT',
                    body: JSON.stringify(getters.getSaveObj),
                    headers: { "Content-Type": "application/json", Token: getCookieToken() },
                });
            }
            else {

                result = await fetch(apiUrl + '/api/v2/console', {

                    method: 'POST',
                    body: JSON.stringify(getters.getSaveObj),
                    headers: { "Content-Type": "application/json", Token: getCookieToken() },
                });

            }

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }
            else if (result.status === 200 || result.status === 201) {

                let json = await result.json()

                commit('updateId', json.id)
            }
            else if (!(result.status === 204 || result.status === 201 || result.status === 200)) {

                throw new Error(await result.text())
            }

        },
    },

    mutations: {

        updateConsole(state, _game_console) {

            state.game_console = _game_console
        },

        updateGamesForConsole(state, val){
            
            state.games = val;
        },

        updateGamePadsForConsole(state, val){
            
            state.gamepads = val;
        },

        updateId(state, id) {

            state.game_console.id = id;
        }


    },

    state: {

        game_console: null,
        games : [],
        gamepads: [],
    },


    getters: {

        getGameConsoleObj(state) {

            return state.game_console;

        },

        getGamesForConsole(state){

            return state.games;
        },

        getGamePadsForConsole(state){

            return state.gamepads;
        },

        getSaveObj(state){

            let obj = state.game_console;
            obj.games = state.games;
            obj.gamepads = state.gamepads;

            if(obj.id != null)
                obj.id = parseInt(obj.id);

            return obj;
        }

    }

}

