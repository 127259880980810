var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"green","disabled":_vm.readonly},on:{"click":function($event){_vm.dialog_person_create = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('CreatePersonDialog',{ref:"ref_person_create_dialog_form",on:{"closeEvent":_vm.createPersonCloseEvent}})]}}])},[_c('span',[_vm._v("создать нового")])]),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.person_items,"loading":_vm.isLoading,"search-input":_vm.search,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"symbol","label":"Выберите клиента","no-filter":"","hide-no-data":"","rules":[function () { return !!_vm.person_select || 'Заполните поле'; }],"disabled":_vm.readonly},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Поиск клиента , введите номер телефона или фио")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"indigo darken-4","label":"","dark":""}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account ")]),_c('span',{domProps:{"textContent":_vm._s(
                    item.last_name +
                    ' ' +
                    item.first_name +
                    ' | ' +
                    item.phone_number
                  )}})],1)]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.first_name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.first_name + ' ' + item.last_name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.phone_number)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account")])],1)]}}]),model:{value:(_vm.person_select),callback:function ($$v) {_vm.person_select=$$v},expression:"person_select"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.person_select != null)?_c('EditProfileBtn',{ref:"ref_profile_edit_btn"}):_vm._e(),(_vm.person_select != null)?_c('PhoneBtn'):_vm._e(),(_vm.person_select != null)?_c('WhatsAppBtn'):_vm._e(),(_vm.person_select != null)?_c('OpenUrlBtn'):_vm._e(),_c('PassportCheckBtn',{ref:"ref_passport_check_btn"}),_c('FsspBtn',{ref:"ref_fssp_btn"}),_c('DrgBtn',{ref:"drg_ref_btn"}),_c('RentHistoryBtn',{ref:"ref_rent_history_btn"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }