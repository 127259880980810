<template>
  <v-form v-model="valid" ref="source_form" lazy-validation>
    <v-autocomplete
      placeholder="Источник"
      v-model="source"
      :rules="[() => !!source || 'Заполните поле']"
      required
      :items="source_items"
      label="Источник"
      hide-no-data
      clearable
      flat
      append-icon="mdi-form-dropdown"
    ></v-autocomplete>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      source_items: [
        "Инстаграм",
        "Авито",
        "Ватсап",
        "Телефонный звонок",
        "Телеграм",
        "Вк",
      ],
      source: null,
      valid: true,
    };
  },

  methods: {
    ...mapGetters(["getDelivered", "getRentSource"]),
    ...mapMutations(["updateSource"]),

    validate() {
      this.$refs.source_form.validate();
      return this.valid;
    },
  },

  watch: {
    source(val) {
      this.updateSource(val);
    },
  },

  mounted: function () {
    this.source = this.getRentSource();
  },
};
</script>

<style>
</style>