import { apiUrl, getCookieToken, setCookie } from "../../conf";

export default {

    actions: {

        async fetchGetRent({ commit }, id) {

            const result = await fetch(apiUrl + '/api/v2/rent/' + id , {

                method: 'GET',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                let rent = await result.json()

                commit('updateRentId', rent.id);
                commit('updateGameConsoleId', rent.game_console);
                commit('updatePeronsId', rent.person);
                commit('updateDelivered', rent.delivered);
                commit('updateDeliveryAddress', rent.delivery_address);
                commit('updateDescription', rent.description);
                commit('updateSource', rent.source);
                commit('updateType', rent.type);
                //commit('updateSelectedGamesForRent', rent.games);
                commit('updateProlongations', rent.prolongations);
                commit('updateCompleted', rent.completed);
                commit('updatePreorder', rent.preorder);
                commit('updateBeginDate', rent.begin_date);
                commit('updateEndDate', rent.end_date);
                commit('updatePrice', rent.price);
                commit('updateRentLoaded', rent);

            }
        },

        async fetchSavePreOrder({ commit, getters }) {

            let result = null;

            if (getters.getRentId != null) {
                result = await fetch(apiUrl + '/api/v2/rent/' + getters.getRentId , {

                    method: 'PUT',
                    body: JSON.stringify(getters.getRentObj),
                    headers: { "Content-Type": "application/json", Token: getCookieToken() },
                });
            }
            else {

                result = await fetch(apiUrl + '/api/v2/rent', {

                    method: 'POST',
                    body: JSON.stringify(getters.getRentObj),
                    headers: { "Content-Type": "application/json", Token: getCookieToken() },
                });

            }

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }
            else if (result.status === 200 || result.status === 201) {

                let json = await result.json()

                commit('updateRentId', json.id)
            }
            else if (!(result.status === 204 || result.status === 201 || result.status === 200)) {

                throw new Error(await result.text())
            }

        },

        async fetchDeleteRent({ getters }) {

            const result = await fetch(apiUrl + '/api/v2/rent/' + getters.getRentId, {

                method: 'DELETE',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())
            }
        },

        async fetchCompletedRent({ commit, getters }) {

            const result = await fetch(apiUrl + '/api/v2/rent/' + getters.getRentId + '/completed', {

                method: 'POST',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())
            }
            else {

                commit('updateCompleted', true);
            }
        },

        async fetchDeliveredRent({ commit, getters }) {

            const result = await fetch(apiUrl + '/api/v2/rent/' + getters.getRentId + '/delivered', {

                method: 'POST',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())
            }
            else {

                commit('updateDelivered', true);
            }
        },

        async fetchCancelDeliveredRent({ commit, getters }) {

            const result = await fetch(apiUrl + '/api/v2/rent/' + getters.getRentId + '/delivered/cancel', {

                method: 'POST',
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            });

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())
            }
            else {

                commit('updateDelivered', false);
            }
        },
    },

    mutations: {


        updateRentId(state, val) {

            state.id = val;
        },

        updateBeginDate(state, val) {

            state.begin_date = val;
        },

        updateEndDate(state, val) {

            state.end_date = val;

        },

        updatePrice(state, val) {

            state.price = val;
        },


        updatePeronsId(state, val) {

            state.person_id = val;
        },

        updateGameConsoleId(state, val) {

            state.game_console_id = val;
        },


        updateDelivered(state, val) {

            state.delivered = val;
        },

        updateCompleted(state, val) {

            state.completed = val
        },

        updatePreorder(state, val) {

            state.preorder = val;
        },

        updateDescription(state, val) {

            state.description = val;
        },

        updateDeliveryAddress(state, val) {

            state.delivery_address = val;
        },


        updateSource(state, val) {

            state.source = val;
        },

        updateType(state, val) {

            state.type = val;
        },

        updateSelectedGamesForRent(state, list) {

            state.games = list;
        },

        updatePersonObj(state, item) {

            state.person = item;
        },

        updateGameConsoleObj(state, item) {

            state.game_console = item;
        },

        updateRentLoaded(state, item) {

            state.rent_loaded = item;
        },

        updateProlongations(state, list) {

            state.prolongations = list;
        },

    },

    state: {


        person: null, // объект
        game_console: null, //объект консоли
        rent_loaded: null,


        id: null,
        begin_date: null,
        end_date: null,
        price: null,
        person_id: null, // id 
        game_console_id: null,
        completed: null,
        preorder: null,
        delivered: false, //предзаказ выполнен (оборудование передано клиенту)
        description: null,
        delivery_address: null,
        source: null,
        type: null,
        games: [],
        prolongations: []
    },

    getters: {


        getRentObjLoaded(state) {

            return state.rent_loaded;
        },

        //то что будем сохранять
        getRentObj(state) {

            let preorder = {

                id: state.id,
                begin_date: state.begin_date,
                end_date: state.end_date,
                price: state.price,
                person: state.person.id,
                game_console: state.game_console != null ? state.game_console.id : null,
                description: state.description,
                delivered: state.delivered,
                preorder: state.delivered ? false : true,
                completed: state.completed === null ? false : state.completed,
                delivery_address: state.delivery_address,
                source: state.source,
                type: state.type,
                games: state.games.map(function (game) {

                    return game.id;
                }),
                prolongations: state.prolongations,
            }

            return preorder;
        },

        getRentId(state) {

            return state.id;
        },

        getPersonId(state) {

            return state.person;
        },

        getGameConsoleId(state) {

            return state.game_console_id;
        },


        getDescription(state) {

            return state.description;
        },

        getDelivered(state) {

            return state.delivered;
        },

        getPreorder(state) {

            return state.preorder;
        },

        getCompleted(state) {

            return state.completed;
        },

        getRentSource(state) {

            return state.source;
        },

        getRentType(state) {

            return state.type;
        },

        getDeliveryAddress(state) {
            return state.delivery_address;
        },

        getProlongations(state) {

            return state.prolongations;
        },

        getBeginDate(state) {

            return state.begin_date;

        },

        getEndDate(state) {

            return state.end_date
        },

        getPrice(state) {

            return state.price;

        },

        getSelectedPersonForRent(state) {

            return state.person;
        },

        getSelectedGameConsoleForRent(state) {

            return state.game_console;
        },

        getSelectedGamesForRent(state) {

            return state.games;
        },

    }
}