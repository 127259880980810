import { apiUrl, getCookieToken } from "../conf";

export default {

    actions: {

        async fetchCheckDrg({ commit }, person) {

            const result = await fetch(apiUrl + '/api/secret_check?firstname=' + person.first_name + "&secondname=" + person.middle_name + "&lastname=" + person.last_name + "&birthdate=" + person.b_day,
                {

                    method: 'GET',
                    mode: "cors",
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: { 
                        "Content-Type": "application/json",  
                        'Token': getCookieToken() },
                });

            if (result.status === 200) {

                let json = await result.json();

                commit('updateDrg', json)
            }
            else if(result.status === 204){

                commit('clearDrg');
            }
            else {

                commit('clearDrg');

                throw new Error('Ошибка при проверка по дрг');
            }
        },
    },

    mutations: {

        updateDrg(state, item) {
            state.drg_data = item;
        },

        clearDrg(state) {

            state.drg_data = null;
        }
    },

    state: {

        drg_data: null,
    },

    getters: {

        getDrg(state) {

            return state.drg_data;
        }
    }

}