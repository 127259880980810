export const apiUrl = getApi()
//export const apiUrl = 'http://localhost:5000'

export function getCookieToken() {

    var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, "$1");

    return cookieValue
}

export function getApi(){

    return window.location.origin + "/app"
}

export function getCookieIsAuth() {

    var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)isauth\s*=\s*([^;]*).*$)|^.*$/, "$1");

    if (cookieValue != undefined && cookieValue.startsWith('true'))
        return true
    if (cookieValue != undefined && cookieValue.startsWith('false'))
        return false
    else
        return false
}

export function getDadataConfig(){

    var tokenDadata = document.cookie.replace(/(?:(?:^|.*;\s*)tokenDadata\s*=\s*([^;]*).*$)|^.*$/, "$1");

    var secretDadata = document.cookie.replace(/(?:(?:^|.*;\s*)secretDadata\s*=\s*([^;]*).*$)|^.*$/, "$1");

    var regionCode = document.cookie.replace(/(?:(?:^|.*;\s*)regionCode\s*=\s*([^;]*).*$)|^.*$/, "$1");

    var regionName = decodeURI(document.cookie.replace(/(?:(?:^|.*;\s*)regionName\s*=\s*([^;]*).*$)|^.*$/, "$1"));

    return {
        
        'tokenDadata' : 'Token ' + tokenDadata,
        'secretDadata' : secretDadata,
        'regionCode' : regionCode,
        'regionName' : regionName,

    }
}

export function setCookie(name, value, options = {}) {


    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options,
    };



    // if (options.expires instanceof Date) {
    //     options.expires = options.expires.toUTCString();
    // }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;

}

