import { apiUrl, getCookieToken, setCookie } from "../../conf";

export default {

    actions: {
        async fetchDownloadContract(ctx, id) {


            const result = await fetch(apiUrl + `/api/v2/rent/${id}/contract`, {

                method: 'GET',
                headers: {

                    "Token": getCookieToken()
                }

            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }



            if (!result.ok) {

                let text = await result.text()

                if (text != undefined && text.length > 0) {
                    throw new Error(text)
                }
                else
                    throw new Error(result.statusText)

            }
            else {

                const blob = await result.blob()

                ctx.commit('updateContractBlob', blob)
            }
        },
    },

    mutations: {
        updateContractBlob(state, blob) {

            state.contract_blob = blob;
        },

    },

    state: {

        contract_blob: null,
    },

    getters: {

        getBlobContract(state) {

            return state.contract_blob;
        }

    }
}