<template>
<div>
  <v-card max-width="400" class="mx-auto">
    <v-card-title>Авторизация</v-card-title>
    <v-form>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              name="input-10-1"
              label="Логин"
              v-model="user_name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="password"
              type="password"
              name="input-10-1"
              label="Пароль"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-btn
            color="primary"
            class="mx-auto"
            @click="login()"
            :loading="loading"
            >Войти</v-btn
          >
        </v-row>
      </v-container>
    </v-form>
  </v-card>

          <v-snackbar
          v-model="snackbar_error"
          timeout="1500"
          centered
          color="red darken-3"
          elevation="24"
          multi-line
        >
          Ошибка авторизации

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar_error = false"
            >
              Закрыть
            </v-btn>
          </template>
        </v-snackbar>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      user_name: null,
      password: null,
      loading: false,
      snackbar_error : false
    };
  },

  methods: {
    ...mapActions(["fetchLogin"]),
    ...mapGetters(["getToken"]),

    async login() {
      if (
        this.user_name === null ||
        this.user_name === "" ||
        this.password === null ||
        this.password === ""
      )
        return;

      try {
        this.loading = true;
        let data = {
          username: this.user_name,
          password: this.password,
        };
        await this.fetchLogin(data);

        console.log(this.getToken());

        window.location.href = '/';
      } catch {
        this.loading = false;
        this.snackbar_error = true;
      }
    },
  },
};
</script>

<style>
</style>