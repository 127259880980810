import { apiUrl, getCookieToken,  setCookie} from "../conf";

export default {

    actions: {

        async fetchGetIncomePriceByConsole(ctx, param) {

            let result = null;

            if (param.rent_type === null || param.rent_type === '')
                result = await fetch(apiUrl + '/api/reports/income_price_by_console?begin_date=' + param.begin_date + '&end_date=' + param.end_date, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })
            else
                result = await fetch(apiUrl + '/api/reports/income_price_by_console?begin_date=' + param.begin_date + '&end_date=' + param.end_date + '&rent_type=' + param.rent_type, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })


            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                if ((await result.text()).length > 0)
                    throw new Error(await result.text())
                else
                    throw new Error(result.statusText)

            }
            else {

                const list = await result.json()

                ctx.commit('incomePriceByConsoleList', list)
            }

        },

        async fetchGetIncomePriceByWeekDay(ctx, param) {

            let result = null;

            if (param.rent_type === null || param.rent_type === '')
                result = await fetch(apiUrl + '/api/reports/income_price_by_weekday?begin_date=' + param.begin_date + '&end_date=' + param.end_date, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })
            else
                result = await fetch(apiUrl + '/api/reports/income_price_by_weekday?begin_date=' + param.begin_date + '&end_date=' + param.end_date + '&rent_type=' + param.rent_type, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                if ((await result.text()).length > 0)
                    throw new Error(await result.text())
                else
                    throw new Error(result.statusText)

            }
            else {

                const list = await result.json()

                ctx.commit('incomePriceByWeekDayList', list)
            }

        },

        async fetchGetIncomePriceByDay(ctx, param) {

            let result = null;

            if (param.rent_type === null || param.rent_type === '')
                result = await fetch(apiUrl + '/api/reports/income_price_by_day?begin_date=' + param.begin_date + '&end_date=' + param.end_date, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })
            else
                result = await fetch(apiUrl + '/api/reports/income_price_by_day?begin_date=' + param.begin_date + '&end_date=' + param.end_date + '&rent_type=' + param.rent_type, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })


            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                if ((await result.text()).length > 0)
                    throw new Error(await result.text())
                else
                    throw new Error(result.statusText)

            }
            else {

                const list = await result.json()

                ctx.commit('incomePriceByDayList', list)
            }

        },

        async fetchGetRentSourceStats(ctx, param) {

            let result = null;

            if (param.rent_type === null || param.rent_type === '')
                result = await fetch(apiUrl + '/api/reports/rent_source_stats?begin_date=' + param.begin_date + '&end_date=' + param.end_date, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })
            else
                result = await fetch(apiUrl + '/api/reports/rent_source_stats?begin_date=' + param.begin_date + '&end_date=' + param.end_date + '&rent_type=' + param.rent_type, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })


            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                if ((await result.text()).length > 0)
                    throw new Error(await result.text())
                else
                    throw new Error(result.statusText)

            }
            else {

                const list = await result.json()

                ctx.commit('rentSourceStats', list)
            }

        },

        async fetchGetRentStats(ctx, param) {

            let result = null;

            if (param.rent_type === null || param.rent_type === '')
                result = await fetch(apiUrl + '/api/reports/rent_stats?begin_date=' + param.begin_date + '&end_date=' + param.end_date, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })
            else
                result = await fetch(apiUrl + '/api/reports/rent_stats?begin_date=' + param.begin_date + '&end_date=' + param.end_date + '&rent_type=' + param.rent_type, {

                    method: 'GET',
                    headers: {

                        "Token": getCookieToken()
                    }

                })


            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                if ((await result.text()).length > 0)
                    throw new Error(await result.text())
                else
                    throw new Error(result.statusText)

            }
            else {

                const data = await result.json()

                ctx.commit('rentStats', data)
            }

        },
    },

    mutations: {

        incomePriceByConsoleList(state, list) {

            state.incomePriceByConsoleList = list;
        },

        incomePriceByWeekDayList(state, list) {

            state.incomePriceByWeekDayList = list;
        },

        incomePriceByDayList(state, list) {

            state.incomePriceByDayList = list;
        },

        rentSourceStats(state, list){

            state.rentSourceStats = list;
        },

        rentStats(state, data){

            state.rentStats = data;
        }
    },

    state: {

        incomePriceByConsoleList: [],
        incomePriceByWeekDayList: [],
        incomePriceByDayList: [],
        rentSourceStats:[],
        rentStats: null
    },

    getters: {

        getIncomePriceByConsoleList(state) {

            return state.incomePriceByConsoleList
        },

        getIncomePriceByWeekDayList(state) {

            return state.incomePriceByWeekDayList
        },

        getIncomePriceByDayList(state) {

            return state.incomePriceByDayList
        },

        getRentSourceStats(state){

            return state.rentSourceStats;
        },

        getRentStats(state){

            return state.rentStats;
        }
    }
}