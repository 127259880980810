<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-row>
      <v-col cols="12" md="12">
        <v-toolbar dense>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="green"
                v-on="on"
                v-bind="attrs"
                @click="dialog_person_create = true"
                :disabled="readonly"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <CreatePersonDialog
                ref="ref_person_create_dialog_form"
                v-on:closeEvent="createPersonCloseEvent"
              />
            </template>
            <span>создать нового</span>
          </v-tooltip>
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="person_select"
              :items="person_items"
              :loading="isLoading"
              :search-input.sync="search"
              chips
              clearable
              hide-details
              hide-selected
              item-text="name"
              item-value="symbol"
              label="Выберите клиента"
              no-filter
              hide-no-data
              :rules="[() => !!person_select || 'Заполните поле']"
              :disabled="readonly"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Поиск клиента , введите номер телефона или
                    фио</v-list-item-title
                  >
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="indigo darken-4"
                  class="white--text"
                  v-on="on"
                  label
                  dark
                >
                  <v-icon left> mdi-account </v-icon>
                  <span
                    v-text="
                      item.last_name +
                      ' ' +
                      item.first_name +
                      ' | ' +
                      item.phone_number
                    "
                  ></span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                  color="indigo"
                  class="text-h5 font-weight-light white--text"
                >
                  {{ item.first_name.charAt(0) }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.first_name + ' ' + item.last_name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.phone_number"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </v-col>
        </v-toolbar>

        <v-col cols="12" sm="12">
          <EditProfileBtn
            ref="ref_profile_edit_btn"
            v-if="person_select != null"
          />
          <PhoneBtn v-if="person_select != null" />
          <WhatsAppBtn v-if="person_select != null" />
          <OpenUrlBtn v-if="person_select != null" />
          <PassportCheckBtn ref="ref_passport_check_btn" />
          <FsspBtn ref="ref_fssp_btn" />
          <DrgBtn ref="drg_ref_btn"/>
          <RentHistoryBtn ref="ref_rent_history_btn" />
        </v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CreatePersonDialog from "./Dialogs/CreatePersonDialog.vue";
import EditProfileBtn from "./Buttons/EditProfileBtn.vue";
import PhoneBtn from "./Buttons/PhoneBtn.vue";
import WhatsAppBtn from "./Buttons/WhatsAppBtn.vue";
import OpenUrlBtn from "./Buttons/OpenUrlBtn.vue";
import PassportCheckBtn from "./Buttons/PassportCheckBtn.vue";
import FsspBtn from "./Buttons/FsspBtn.vue";
import RentHistoryBtn from "./Buttons/RentHistoryBtn.vue";
//import JusticeBtn from "./Buttons/JusticeBtn.vue";
import DrgBtn from "./Buttons/DrgBtn.vue";

export default {
  components: {
    CreatePersonDialog,
    EditProfileBtn,
    PhoneBtn,
    WhatsAppBtn,
    OpenUrlBtn,
    PassportCheckBtn,
    FsspBtn,
    RentHistoryBtn,
    //JusticeBtn,
    DrgBtn
  },
  data: () => ({
    isLoading: false,
    person_items: [],
    person_select: null,
    search: null,
    dialog: false,
    valid: false,

    dialog_person_create: false,
  }),

  watch: {
    person_select(val) {
      this.updatePersonObj(val);

      if (!this.readonly) {
        this.$refs.ref_passport_check_btn.passportCheck();
        this.$refs.ref_rent_history_btn.getRents();
        this.$refs.ref_fssp_btn.fsspCheck();
        //this.$refs.ref_justice_btn.justiceCheck();
        this.$refs.drg_ref_btn.drgCheck();
      }

      this.$emit("personSelectedEvent", val);
    },
    search(val) {
      this.searchPerson(val);
    },

    dialog_person_create(val) {
      if (val) this.$refs.ref_person_create_dialog_form.show();
    },
  },

  props: ["readonly"],

  created: function () {
    if (this.getSelectedPersonForRent() != null) {
      this.person_select = this.getSelectedPersonForRent();
      this.person_items.push(this.getSelectedPersonForRent());
    }
  },

  methods: {
    ...mapGetters(["getFoundPersons", "getSelectedPersonForRent", "getPersonObj"]),
    ...mapActions([
      "fetchGetAllPerson",
      "fetchFindPersons",
      "setSelectPerson",
      "fetchGetPersonById",
    ]),

    ...mapMutations(["updatePersonObj"]),

    validate() {
      this.$refs.form.validate();
      return this.valid;
    },

    async searchPerson(query) {
      if (query === null || query === "") return;

      this.isLoading = true;
      this.person_items = [];
      await this.fetchFindPersons(query);

      this.person_items = this.getFoundPersons();

      this.isLoading = false;
    },

    async createPersonCloseEvent(new_person_id) {
      this.dialog_person_create = false;

      if (new_person_id === null) return;

      this.isLoading = true;

      await this.fetchGetPersonById(new_person_id);

      this.person_select = this.getPersonObj();

      this.person_items = [this.person_select];

      this.isLoading = false;
    },
  },
};
</script>