<template>
  <v-autocomplete
    v-model="gamepads_selected"
    :items="gamepads_items"
    :loading="isLoading"
    chips
    clearable
    hide-details
    hide-selected
    hide-no-data
    item-text="number"
    item-value="symbol"
    label="Геймпады"
    multiple
    deletable-chips
    append-icon="mdi-controller-classic-outline"
    return-object
    :disabled="readonly"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-action>Поиск геймпадов</v-list-item-action>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="indigo darken-4"
        class="white--text"
        v-on="on"
        label
        dark
        small
        close
        @click:close="closeChips(item)"
      >
        <v-icon left> mdi-controller-classic </v-icon>
        <span v-text="item.number"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="indigo darken-4"
        class="text-h7 font-weight-light white--text"
      >
        {{ item.console_type.code.toUpperCase()}}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="textTitle(item)"></v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon color="indigo">mdi-controller-classic</v-icon>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      gamepads_selected: [],
      isLoading: false,
    };
  },

  computed:{

      gamepads_items(){
        let items = [];

        if(this.getGameConsoleObj()!=null && this.getGameConsoleObj().console_type != null){
          let items_busy = this.getGamePadList().filter((pad)=>pad.console_type.id === this.getGameConsoleObj().console_type && pad.current_game_console!=null);
          let items_free = this.getGamePadList().filter((pad)=>pad.console_type.id === this.getGameConsoleObj().console_type && pad.current_game_console==null);

          items_busy.sort(function(a,b){

              if (a.current_game_console!=null && b.current_game_console!=null && a.current_game_console.id < b.current_game_console.id){

                  return -1;
              }
              if (a.current_game_console!=null && b.current_game_console!=null && a.current_game_console.id > b.current_game_console.id){

                  return 1;
              }
              else
                return 0;
          });

          items_free.sort(function(a,b){

              if(a.number < b.number){

                  return  -1 ;
              }
              if(a.number > b.number){

                  return  1 ;
              }
              else
                return 0;

          })

            items = items_free.concat(items_busy);
          
          }

        return items;
      }
  },
  props: ["readonly"],

  watch: {
    gamepads_selected(newVal) {
      this.updateGamePadsForConsole(
        newVal.map(function (gamepad) {
          return gamepad.id;
        })
      );
    },
  },

  methods: {
    ...mapGetters(["getGamePadList", "getGamePadsForConsole", "getGameConsoleObj"]),
    ...mapMutations(["updateGamePadsForConsole"]),

    closeChips(val) {
      let list = [];

      for (let gamepad of this.gamepads_selected) {
        if (val.id != gamepad.id) list.push(gamepad);
      }

      this.gamepads_selected = list;
    },

    textTitle(gamepad){

        if(gamepad.current_game_console != null)
            return gamepad.number + ' | ' + gamepad.current_game_console.console_type.code.toUpperCase() + ' №' + gamepad.current_game_console.number;
        else
             return gamepad.number + ' | свободен';
    }
  },


  created: async function () {
    this.isLoading = true;
   

     for (let gamepad_id of this.getGamePadsForConsole()) {
      this.gamepads_selected.push(
        this.getGamePadList().filter((g) => g.id == gamepad_id)[0]
      );
    } 

    this.isLoading = false;
  },
};
</script>

<style>
</style>