<template>
  <v-dialog v-model="dialog" width="400">
    <v-card>
      <v-card-title>
        {{ rent_info }} | Пролонгация # {{ prolongation_num }}
      </v-card-title>
      <v-list-item-content>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-col cols="12" md="12">
            <v-text-field
              type="date"
              label="Дата начала"
              v-model="begin_date"
              readonly
            >
            </v-text-field>
            <v-btn
              x-small
              @click="add_day_click = 1"
              color="light-green lighten-4"
            >
              1 дн.</v-btn
            >
            <v-btn
              x-small
              @click="add_day_click = 2"
              style="margin-left: 10px"
              color="light-green lighten-4"
            >
              2 дн.</v-btn
            >
            <v-btn
              x-small
              @click="add_day_click = 3"
              style="margin-left: 10px"
              color="light-green lighten-4"
            >
              3 дн.</v-btn
            >
            <v-btn
              x-small
              @click="add_day_click = 4"
              style="margin-left: 10px"
              color="light-green lighten-4"
            >
              4 дн.</v-btn
            >
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              type="date"
              label="Дата окончания"
              v-model="end_date"
              :rules="endDateRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              type="number"
              label="Цена"
              v-model="price"
              :rules="[
                () => !!price || 'Заполните поле',
                () => price > 0 || `Должно быть больше 0`,
              ]"
            >
            </v-text-field>
          </v-col>
        </v-form>
      </v-list-item-content>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="savePeriod()" :loading="loading">
          Сохранить
        </v-btn>
        <v-btn color="primary" @click="closeForm()" :loading="loading">
          Закрыть
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      prolongation_num: 0,
      begin_date: null,
      end_date: null,
      price: 0,
      add_day_click: 0,
      dialog: false,
      valid: true,
      loading: false,
    };
  },

  props: {
    rent: Object,
  },

  watch: {
    add_day_click(newVal) {
      if (newVal === 0) return;

      if (this.begin_date != null) {
        let date_start = new Date(Date.parse(this.begin_date));

        let end = date_start;

        end.setDate(date_start.getDate() + newVal);

        this.end_date = end.toISOString().slice(0, 10);
      }
    },
  },

  computed: {
    rent_info() {
      return this.rent != null
        ? this.rent.person.last_name + " " + this.rent.person.first_name
        : "";
    },
    endDateRules() {
      let rules = [];

      rules.push(() => !!this.end_date || "Заполните поле");

      rules.push(
        () =>
          this.end_date > this.begin_date ||
          `Должно быть больше ${this.dateToString(this.begin_date)}`
      );

      return rules;
    },
  },

  methods: {
    ...mapActions(["fetchAddProlongation"]),

    dateToString(date) {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        timezone: "UTC",
      };

      let result = new Date(date).toLocaleString("ru", options);

      return result;
    },

    savePeriod() {
      let form_is_valid = this.$refs.form.validate();

      if (!form_is_valid) return;

      let data = {
        rent_id: this.rent.id,
        begin_date: this.begin_date,
        end_date: this.end_date,
        price: this.price,
      };

      try{
      this.loading = true;
      this.fetchAddProlongation(data);
      this.loading = false;
      this.dialog = false;
      this.$emit('addProlongationSuccessEvent');
      }
      catch(e){

          console.log(e.message);
      }
    },

    closeForm() {
      this.dialog = false;
    },

    openDialog() {
      this.begin_date =
        this.rent.prolongations.length > 0
          ? this.rent.prolongations.slice(-1)[0].end_date
          : this.rent.end_date;

      this.prolongation_num = this.rent.prolongations.length + 1;
      this.price = 0;
      this.end_date = null;
      this.dialog = true;
    },
  },
};
</script>

<style>
</style>