<template>
  <div>
    <v-card max-width="177" class="mx-auto" :color="color">
      <v-row class="mx-auto">
        <v-col cols="8">
          <v-list-item-title style="font-size: 15px; font-weight: bolder">
            {{ personFirstName }}
          </v-list-item-title>
          <v-list-item-title style="font-size: 15px; font-weight: bolder">
            {{ personLastName }}
          </v-list-item-title>
        </v-col>
        <v-col cols="4">
          <v-btn icon fab color="primary" small :href="'/rent/' + rent.id"
            ><v-icon>mdi-circle-edit-outline</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-row class="mx-auto" style="margin-bottom: -20; margin-top: 0px">
        <v-col>
          <v-btn icon fab color="black" x-small @click="openCall()"
            ><v-icon>mdi-phone</v-icon></v-btn
          >
          <v-btn icon fab color="green" x-small @click="openWhatsApp()"
            ><v-icon>mdi-whatsapp</v-icon></v-btn
          >
          <v-btn
            v-if="
              rent.person.social_url != null && rent.person.social_url != ''
            "
            icon
            fab
            color="deep-purple lighten-1"
            x-small
            @click="openSocial()"
            ><v-icon>mdi-web</v-icon></v-btn
          >
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-window v-model="step">
        <v-window-item :value="1" class="mx-auto">
          <FirstSheet
            v-bind:rent="rent"
            v-bind:color="color"
            v-on:callEventRefresh="callEventRefresh()"
          />
        </v-window-item>
      </v-window>

      <v-card-actions v-if="!rent.completed">
        <v-btn
          v-if="rent.preorder"
          x-small
          text
          style="width: 100%"
          @click="rentDeliveredOpenDialog()"
          :disabled="deliveredBtnDisabled"
          :loading="loading_delivered"
          >Выдать
          <v-icon small color="amber lighten-1"> mdi-transfer-right </v-icon>
        </v-btn>

        <v-btn
          v-if="
            !rent.completed &&
            rent.delivered &&
            rent.prolongations.length === 0 &&
            rent.day_amount - rent.day_left === 0
          "
          x-small
          text
          style="width: 100%"
          :loading="loading_delivered_cancel"
          @click="rentDeliveredCancelOpenDialog()"
          :disabled="deliveredCancelBtnDisabled"
          >Отменить выдачу
          <v-icon small color="amber lighten-1"> mdi-transfer-left </v-icon>
        </v-btn>
        <v-btn
          v-if="
            !rent.completed &&
            rent.delivered &&
            rent.day_amount - rent.day_left != 0
          "
          x-small
          text
          style="width: 100%"
          :loading="loading_complete"
          @click="rentCompleteOpenDialog()"
          >Завершить
          <v-icon small color="green"> mdi-check </v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          small
          icon
          @click="downloadContract()"
          :loading="loading_download"
        >
          <v-icon small color="blue lighten-1">
            mdi-file-download-outline
          </v-icon>
        </v-btn>
        <v-btn
          icon
          small
          @click="doubleGisOpen()"
          v-if="rent.delivery_address != null"
        >
          <v-icon small color="blue lighten-1">mdi-truck</v-icon>
        </v-btn>

<!--         <v-btn icon small :href="'/rent/' + this.rent.id">
          <v-icon small color="indigo lighten-1">mdi-pencil</v-icon>
        </v-btn> -->

        <v-btn
          icon
          small
          :loading="loading_delete"
          :disabled="deleteBtnDisabled"
          @click="rentDeleteOpenDialog()"
          ><v-icon small color="red lighten-1">mdi-trash-can</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title>
          {{ dialog_title }}
        </v-card-title>
        <v-card-text>{{ dialog_text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="action_confirmed = true" color="green">
            Да
          </v-btn>
          <v-btn text @click="dialog = false" color="red"> Нет </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar_error"
      :timeout="snackbar_timeout"
      centered
      color="red darken-3"
      elevation="24"
      multi-line
    >
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar_error = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FirstSheet from "./RentCardFirstItem.vue";
//import SecondSheet from "./RentCardSecondItem.vue";

export default {
  components: {
    FirstSheet,
  },
  props: {
    rent: Object,
  },

  watch: {
    dialog(newVal, val) {
      if (newVal == false && val) {
        this.deleted_call = false;
        this.completed_call = false;
        this.delivered_call = false;
        this.delivered_cancel_call = false;
      }
    },

    action_confirmed(confirmed) {
      if (!confirmed) return;

      if (this.completed_call) this.rentCompleteCall();

      if (this.deleted_call) this.rentDeleteCall();

      if (this.delivered_call) this.rentDeliveredCall();

      if (this.delivered_cancel_call) this.rentCancelDeliveredCall();
    },
  },

  data() {
    return {
      step: 1,

      loading_delete: false,
      loading_complete: false,
      loading_delivered: false,
      loading_delivered_cancel: false,
      loading_download: false,

      dialog: false,

      snackbar_error: false,
      snackbar_text: "",
      snackbar_timeout: 1500,
      overlay_visable: false,

      dialog_title: "",
      dialog_text: "",
      action_confirmed: false,

      deleted_call: false,
      completed_call: false,
      delivered_call: false,
      delivered_cancel_call: false,
    };
  },

  computed: {
    color() {
      let grey = "grey lighten-5";
      let yellow = "yellow lighten-4";
      let green = "green lighten-5";
      let red = 'red lighten-4'

      let result = "";

      if (this.rent.completed) {
        result = green;
      } else if (this.rent.preorder) result = grey;
      else if (this.rent.day_left == 0) result = yellow;
      else if (this.rent.day_left < 0) result = red;

      return result;
    },

    deleteBtnDisabled() {
      return this.rent.delivered;
    },

    deliveredBtnDisabled() {
      let now = new Date();

      return (
        this.rent.console === null ||
        this.getNumberOfDays(now, this.rent.begin_date) > 0
      );
    },

    deliveredCancelBtnDisabled() {
      return this.rent.prolongations.length > 0 || !this.rent.delivered;
    },

    personFirstName() {
      return this.rent.person.first_name;
    },

    personLastName() {
      return this.rent.person.last_name;
    },
  },

  methods: {
    ...mapActions([
      "fetchCompletedRentById",
      "fetchDeliveredRentById",
      "fetchCancelDeliveredRentById",
      "fetchDeleteRentById",
      "fetchDownloadContract",
    ]),

    ...mapGetters(["getBlobContract"]),

    getNumberOfDays(start, end) {

      const date1 = new Date(start);
      const date2 = new Date(end);
      date1.setHours(0);
      date1.setMinutes(0);
      date1.setSeconds(0);
      date1.setMilliseconds(0);

      date2.setHours(0);
      date2.setMinutes(0);
      date2.setSeconds(0);
      date2.setMilliseconds(0);


      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
    },

    async downloadContract() {
      this.loading_download = true;

      try {
        await this.fetchDownloadContract(this.rent.id);

        let objURL = window.URL.createObjectURL(this.getBlobContract());

        console.log(objURL);

        var fileLink = document.createElement("a");
        fileLink.href = objURL;
        fileLink.download = this.rent.person.last_name + " Договор.docx";
        fileLink.click();

        URL.revokeObjectURL(objURL);
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }

      this.loading_download = false;
    },

    rentDeleteOpenDialog() {
      this.dialog = true;
      this.deleted_call = true;
      this.dialog_title = "Удаление";
      this.dialog_text = `Удалить аренду ? (${this.rent.person.last_name} ${this.rent.person.first_name})`;
    },

    openWhatsApp() {
      window.open(
        "https://api.whatsapp.com/send?phone=" +
          this.rent.person.phone_number +
          "&text=."
      );
    },

    openSocial() {
      window.open(this.rent.person.social_url);
    },

    openCall() {
      window.location.href = "tel:+" + this.rent.person.phone_number;
    },

    doubleGisOpen() {
      window.open(
        "https://2gis.ru/lipetsk/search/" + this.rent.delivery_address
      );
    },

    rentDeliveredOpenDialog() {
      this.dialog = true;
      this.delivered_call = true;
      this.dialog_title = `Выдача`;
      this.dialog_text = `Сменить статус на 'Выдан' (${this.rent.person.last_name} ${this.rent.person.first_name})?`;
    },

    rentDeliveredCancelOpenDialog() {
      this.dialog = true;
      this.delivered_cancel_call = true;
      this.dialog_title = `Отмена выдачи`;
      this.dialog_text = `Отменить выдачу заказа (${this.rent.person.last_name} ${this.rent.person.first_name})?`;
    },

    rentCompleteOpenDialog() {
      this.dialog = true;
      this.completed_call = true;
      this.dialog_title = "Завершение";
      this.dialog_text = `Завершить аренду ? (${this.rent.person.last_name} ${this.rent.person.first_name})`;
    },

    async rentDeliveredCall() {
      this.dialog = false;
      this.loading_delivered = true;
      try {
        console.log("Выдача заказа");
        await this.fetchDeliveredRentById(this.rent.id);
        this.callEventRefresh();
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }
      this.delivered_call = false;
      this.action_confirmed = false;
      this.loading_delivered = false;
    },

    async rentCancelDeliveredCall() {
      this.dialog = false;
      this.loading_delivered_cancel = true;
      try {
        console.log("Отмена выдачи заказа");
        await this.fetchCancelDeliveredRentById(this.rent.id);
        this.callEventRefresh();
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }
      this.delivered_cancel_call = false;
      this.action_confirmed = false;
      this.loading_delivered_cancel = false;
    },

    async rentDeleteCall() {
      this.dialog = false;
      this.loading_delivered_cancel = true;
      try {
        console.log("Удаление заказа");
        await this.fetchDeleteRentById(this.rent.id);
        this.callEventRefresh();
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }
      this.deleted_call = false;
      this.action_confirmed = false;
      this.loading_delivered_cancel = false;
    },

    async rentCompleteCall() {
      this.dialog = false;
      this.loading_complete = true;
      try {
        console.log("Завершение заказа");
        await this.fetchCompletedRentById(this.rent.id);
        this.callEventRefresh();
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }
      this.completed_call = false;
      this.action_confirmed = false;
      this.loading_complete = false;
    },

    callEventRefresh() {
      this.$emit("callEventRefreshRents");
    },
  },
};
</script>

<style>
</style>