<template>
  <div>
    <v-sheet height="100%" :color="color">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title style="font-size: 15px; font-weight: 500">
            {{ gameConsoleInfo }}</v-list-item-title
          >
          <v-divider></v-divider>
          <v-list-item-title style="font-size: 12px; font-weight: 500">{{
            periodInfo
          }}</v-list-item-title>
          <v-divider></v-divider>
          <v-list-item-title style="font-size: 12px"
            >{{ priceInfo }}
          </v-list-item-title>
            <v-list-item-title style="font-size: 11px" v-if="rent.preorder"
            >Источник : {{ rent.source }}
          </v-list-item-title>
          <v-list-item-title
            style="font-size: 12px"
            v-if="!rent.preorder && !rent.completed"
            >{{ dayLeftInfo }}
          </v-list-item-title>
          <v-list-item-title style="font-size: 12px" v-if="!rent.preorder"
            >{{ prolongationInfo }} |
            <v-btn
              x-small
              icon
              color="green"
              @click="openProlongationDialog()"
              :disabled="rent.completed"
              ><v-icon x-small>mdi-plus-thick</v-icon></v-btn
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-sheet>

    <AddProlongation
      ref="add_prolongation_ref"
      v-bind:rent="rent"
      v-on:addProlongationSuccessEvent="callEventRefresh()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddProlongation from "./AddProlongationDialog.vue";

export default {
  props: {
    rent: Object,
    color: String,
  },

  components: {
    AddProlongation,
  },

  data() {
    return {
      loading_download: false,
      snackbar_error: false,
      snackbar_timeout: 1500,
      snackbar_text: "",
    };
  },

  computed: {
    gameConsoleInfo() {
      if (this.rent.console != null)
        return (
          this.rent.console.console_type.code.toUpperCase() +
          " | № " +
          this.rent.console.number +
          " | " +
          this.rent.console.gamepads_count +
          " дж."
        );
      else return this.rent.type + " | " + "НЕ ВЫБРАНА";
    },

    periodInfo() {
      let begin = this.dateToString(this.rent.begin_date).substring(0, 5);

      let end =
        this.rent.prolongations.length === 0
          ? this.dateToString(this.rent.end_date).substring(0, 5)
          : this.dateToString(
              this.rent.prolongations[this.rent.prolongations.length - 1]
                .end_date
            ).substring(0, 5);

      let result = `${begin} - ${end} | ${this.rent.day_amount} дн.`;
      return result;
    },

    priceInfo() {
      return "Сумма: " + this.rent.total_price + " р.";
    },

    dayLeftInfo() {
      return "Осталось дней: " + this.rent.day_left;
    },

    prolongationInfo() {
      return "Продлений: " + this.rent.prolongations.length;
    },
  },

  methods: {
    ...mapActions(["fetchDownloadContract"]),
    ...mapGetters(["getBlobContract"]),

    openProlongationDialog() {
      this.$refs.add_prolongation_ref.openDialog();
    },

    callEventRefresh() {
      this.$emit("callEventRefresh");
    },

    async downloadContract() {
      this.loading_download = true;

      try {
        await this.fetchDownloadContract(this.rent.id);

        let objURL = window.URL.createObjectURL(this.getBlobContract());

        console.log(objURL);

        var fileLink = document.createElement("a");
        fileLink.href = objURL;
        fileLink.download = this.rent.person.last_name + " Договор.docx";
        fileLink.click();

        URL.revokeObjectURL(objURL);
      } catch (e) {
        console.log(e.message);
        this.snackbar_error = true;
        this.snackbar_text = e.message;
      }

      this.loading_download = false;
    },

    dateToString(date) {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        timezone: "UTC",
      };

      let result = new Date(date).toLocaleString("ru", options);

      return result;
    },
  },
};
</script>

<style>
</style>