<template>
  <v-tab-item key="dashboard">
    <div>
      <div style="max-width: 900px; margin: auto">
        <v-overlay :value="overlay_visable">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </div>

      <hr />
      <v-card class="mx-auto">
        <v-card
          v-if="loaded"
          max-width="1050"
          max-height="500"
          style="margin-right: 10px"
        >
          <v-card-title>выручка по месяцам </v-card-title>
          <div style="width: 1000px; height: 400px">
            <bar-chart :chart-data="revenue_from_month"></bar-chart>
          </div>
        </v-card>
      </v-card>
      <hr />
      <v-card class="mx-auto">
        <v-card
          v-if="loaded"
          max-width="1050"
          max-height="500"
          style="margin-right: 10px"
        >
          <v-card-title>ср. выручка в день по месяцам</v-card-title>
          <div style="width: 1000px; height: 400px">
            <bar-chart :chart-data="avg_renevue_day_from_month"></bar-chart>
          </div>
        </v-card>
      </v-card>
      <hr />
      <v-card class="mx-auto">
        <v-card
          v-if="loaded"
          max-width="1050"
          max-height="500"
          style="margin-right: 10px"
        >
          <v-card-title>кол-во новых клиентов по месяцам </v-card-title>
          <div style="width: 1000px; height: 400px">
            <bar-chart :chart-data="new_person_from_mont"></bar-chart>
          </div>
        </v-card>
      </v-card>
      <hr />
      <v-card class="mx-auto">
        <v-card
          v-if="loaded"
          max-width="1050"
          max-height="500"
          style="margin-right: 10px"
        >
          <v-card-title>кол-во аренд по месяцам </v-card-title>
          <div style="width: 1000px; height: 400px">
            <bar-chart :chart-data="rent_count_from_month"></bar-chart>
          </div>
        </v-card>
      </v-card>
      <v-card class="mx-auto">
        <v-card
          v-if="loaded"
          max-width="1050"
          max-height="500"
          style="margin-right: 10px"
        >
          <v-card-title>кол-во заявок из инстаграма </v-card-title>
          <div style="width: 1000px; height: 400px">
            <bar-chart :chart-data="instagram_source"></bar-chart>
          </div>
        </v-card>
      </v-card>
    </div>
  </v-tab-item>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BarChart from "./LineChart";

export default {
  components: { BarChart },
  data() {
    return {
      begin_date: null,
      end_date: null,
      rent_type: "ВСЕ",
      rent_type_items: ["PS4", "PS5", "ВСЕ"],
      overlay_visable: false,
      loaded: false,
      revenue_from_month: null,
      new_person_from_mont: null,
      rent_count_from_month: null,
      avg_renevue_day_from_month: null,
      instagram_source: null,
    };
  },
  methods: {
    ...mapActions([
      "fetchGetIncomePriceByConsole",
      "fetchGetIncomePriceByWeekDay",
      "fetchGetIncomePriceByDay",
      "fetchGetRentSourceStats",
      "fetchGetRentStats",
    ]),
    ...mapGetters([
      "getIncomePriceByConsoleList",
      "getIncomePriceByWeekDayList",
      "getIncomePriceByDayList",
      "getRentSourceStats",
      "getRentStats",
    ]),

    async load() {
      this.overlay_visable = true;

      let years = [2020, 2021, 2022, 2023, 2024, 2025, 2026];
      let revenue = [];
      let new_person_count = [];
      let rent_count = [];
      let avg_renevue_day = [];
      let instagram = [];
      let avito = [];
      let whatsapp = [];
      let phone = [];
      let vk = [];
      let dates = [];

      let date_now = new Date();

      for (let year of years)
        if (year <= date_now.getFullYear())
          for (var i = 0; i < 12; i++) {
            if (year == date_now.getFullYear() && i > date_now.getMonth())
              continue;

            let data = this.getRequestDataByMonth(year, i);

            await this.fetchGetRentStats(data);
            await this.fetchGetRentSourceStats(data);

            let result = this.getRentStats();
            let result_source = this.getRentSourceStats();

            if (
              result_source.filter((r) => {
                return r.source === "Инстаграм";
              }).length > 0
            )
              instagram.push(
                result_source.filter((r) => {
                  return r.source === "Инстаграм";
                })[0].count
              );

            if (
              result_source.filter((r) => {
                return r.source === "Вк";
              }).length > 0
            )
              vk.push(
                result_source.filter((r) => {
                  return r.source === "Вк";
                })[0].count
              );

            if (
              result_source.filter((r) => {
                return r.source === "Авито";
              }).length > 0
            )
              avito.push(
                result_source.filter((r) => {
                  return r.source === "Авито";
                })[0].count
              );

            if (
              result_source.filter((r) => {
                return r.source === "Ватсап";
              }).length > 0
            )
              whatsapp.push(
                result_source.filter((r) => {
                  return r.source === "Ватсап";
                })[0].count
              );

            if (
              result_source.filter((r) => {
                return r.source === "Телефонный звонок";
              }).length > 0
            )
              phone.push(
                result_source.filter((r) => {
                  return r.source === "Телефонный звонок";
                })[0].count
              );

            if (result["total_price"] != null) {
              revenue.push(result["total_price"]);
              new_person_count.push(result["new_person_count"]);
              rent_count.push(result["rent_count"]);
              avg_renevue_day.push(result["avg_renevue_day"]);

              let date = new Date(data.end_date);

              dates.push(date.getMonth() + 1 + "." + date.getFullYear());
            }
          }

      dates = dates.reverse();
      revenue = revenue.reverse();
      new_person_count = new_person_count.reverse();
      rent_count = rent_count.reverse();
      avg_renevue_day = avg_renevue_day.reverse();
      instagram = instagram.reverse();
      vk = vk.reverse();
      whatsapp = whatsapp.reverse();
      phone = phone.reverse();
      avito = avito.reverse();

      this.revenue_from_month = {
        labels: dates,
        datasets: [
          {
            label: "Сумма",
            backgroundColor: "#74FF33",
            data: revenue,
          },
        ],
      };

      this.new_person_from_mont = {
        labels: dates,
        datasets: [
          {
            label: "Кол-во",
            backgroundColor: "#335EFF",
            data: new_person_count,
          },
        ],
      };

      this.rent_count_from_month = {
        labels: dates,
        datasets: [
          {
            label: "Кол-во",
            backgroundColor: "#FF33F3",
            data: rent_count,
          },
        ],
      };

      this.avg_renevue_day_from_month = {
        labels: dates,
        datasets: [
          {
            label: "Сумма",
            backgroundColor: "#FFF333",
            data: avg_renevue_day,
          },
        ],
      };

      this.instagram_source = {
        labels: dates,
        datasets: [
          {
            label: "Кол-во",
            backgroundColor: "#FF6E33",
            data: instagram,
          },
        ],
      };

      this.loaded = true;

      this.overlay_visable = false;
    },

    currentMonth() {
      let begin = new Date();
      begin.setDate(1);
      this.begin_date = begin.toISOString().substring(0, 10);

      let now = new Date();
      this.end_date = now.toISOString().substring(0, 10);
    },

    previousMonth() {
      let begin = new Date();
      begin.setDate(1);
      begin.setMonth(new Date().getMonth() - 1);
      this.begin_date = begin.toISOString().substring(0, 10);

      let end = new Date();
      end.setDate(0);

      this.end_date = end.toISOString().substring(0, 10);
    },

    getRequestDataByMonth(year, month_num) {
      let begin = new Date(year, month_num, 2);

      let end = new Date(year, month_num + 1);

      let now = new Date();

      if (month_num == now.getMonth() && year == now.getFullYear()) end = now;

      let data = {
        begin_date: begin.toISOString().substring(0, 10),
        end_date: end.toISOString().substring(0, 10),
        rent_type: this.rent_type != "ВСЕ" ? this.rent_type : "",
      };

      return data;
    },
  },

  mounted: function () {
    this.load();
  },
};
</script>

<style>
</style>