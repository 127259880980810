<template>
  <div>
    <v-btn
      color="green lighten-2"
      x-small
      style="margin-bottom: 10px"
      @click="addPeriod"
      :disabled="addPeriodBtnDisabled"
      >Добавить пролонгацию
      <v-icon small>mdi-plus</v-icon>
    </v-btn>

    <v-expansion-panels>
      <v-expansion-panel flat>
        <v-expansion-panel-header v-if="periods.length > 0">
          пролонгации ({{ periods.length }}) | {{ prolongationTotalDays }} дн. |
          {{ prolongationTotalPrice }} р.
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <div v-for="(period, index) in periods" :key="period.id">
              <v-col cols="12" md="3">
                <v-btn
                  small
                  @click="editPeriod(index, period)"
                  :color="btnColor(period)"
                  :disabled="btnDisabled(index)"
                >
                  {{ btnTitle(index, period) }}
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </v-col>
            </div>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="text-center">
      <v-dialog v-model="dialog" width="400">
        <v-card>
          <v-card-title>Пролонгация # {{ prolongation_num }}</v-card-title>
          <v-list-item-content>
            <v-form v-model="valid" ref="form" lazy-validation>
              <v-col cols="12" md="12">
                <v-text-field
                  type="date"
                  label="Дата начала"
                  v-model="begin_date"
                  :rules="beginDateRules"
                  readonly
                >
                </v-text-field>
                <v-btn
                  x-small
                  @click="add_day_click = 1"
                  color="light-green lighten-4"
                  :disabled="addDayBtnDisabled"
                >
                  1 дн.</v-btn
                >
                <v-btn
                  x-small
                  @click="add_day_click = 2"
                  style="margin-left: 10px"
                  color="light-green lighten-4"
                  :disabled="addDayBtnDisabled"
                >
                  2 дн.</v-btn
                >
                <v-btn
                  x-small
                  @click="add_day_click = 3"
                  style="margin-left: 10px"
                  color="light-green lighten-4"
                  :disabled="addDayBtnDisabled"
                >
                  3 дн.</v-btn
                >
                <v-btn
                  x-small
                  @click="add_day_click = 4"
                  style="margin-left: 10px"
                  color="light-green lighten-4"
                  :disabled="addDayBtnDisabled"
                >
                  4 дн.</v-btn
                >
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  type="date"
                  label="Дата окончания"
                  v-model="end_date"
                  :rules="endDateRules"
                  :readonly="endDateDisabled"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  type="number"
                  label="Цена"
                  v-model="price"
                  :rules="[
                    () => !!price || 'Заполните поле',
                    () => price > 0 || `Должно быть больше 0`,
                  ]"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-checkbox
                  v-if="deleteVisable"
                  v-model="deleted"
                  label="Удалить"
                >
                </v-checkbox>
              </v-col>
            </v-form>
          </v-list-item-content>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="savePeriod()"> Сохранить </v-btn>
            <v-btn color="primary" @click="closeForm()"> Закрыть </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      periods: [],

      period_id: null,
      begin_date: null,
      end_date: null,
      price: 0,
      prolongation_num: 0,
      deleted: false,
      adding: false,

      add_day_click: 0,
    };
  },

  computed: {
    deleteVisable() {
      if (this.adding || this.getCompleted()) {
        return false;
      } else if (
        (this.period_id != null && this.period_id < 0) ||
        this.prolongation_num === this.periods.length
      ) {
        return true;
      } else {
        return false;
      }
    },

    beginDateRules() {
      let rules = [];

      rules.push(() => !!this.begin_date || "Заполните поле");
      return rules;
    },

    endDateRules() {
      let rules = [];

      rules.push(() => !!this.end_date || "Заполните поле");

      rules.push(
        () =>
          this.end_date > this.begin_date ||
          `Должно быть больше ${this.dateToString(this.begin_date)}`
      );

      return rules;
    },

    prolongationTotalPrice() {
      let result = 0;

      for (let period of this.periods) {
        result += parseInt(period.price);
      }

      return result;
    },

    prolongationTotalDays() {
      let result = 0;

      for (let period of this.periods)
        result += parseInt(
          this.getNumberOfDays(period.begin_date, period.end_date)
        );

      return result;
    },

    unsavedPeriodExists() {
      let result = false;

      let list = this.periods.filter((period) => {
        return period.id < 0;
      });

      if (list.length > 0) result = true;

      return result;
    },

    endDateDisabled() {
      let result = false;

      if (
        (this.period_id > 0 &&
          this.prolongation_num < this.getProlongations().length) ||
        this.getCompleted()
      )
        result = true;

      return result;
    },

    addDayBtnDisabled() {
      return this.period_id > 0;
    },

    addPeriodBtnDisabled() {
      if (
        this.getCompleted() ||
        (this.getRentObjLoaded() != null &&
          !this.getRentObjLoaded().delivered &&
          this.getDelivered()) ||
        this.getRentObjLoaded() === null
      )
        return true;
      else return false;
    },
  },

  watch: {
    add_day_click(newVal) {
      if (newVal === 0) return;

      if (this.begin_date != null) {
        let date_start = new Date(Date.parse(this.begin_date));

        let end = date_start;

        end.setDate(date_start.getDate() + newVal);

        this.end_date = end.toISOString().slice(0, 10);
      }
    },
  },

  methods: {
    ...mapGetters([
      "getProlongations",
      "getCompleted",
      "getDelivered",
      "getRentObjLoaded",
      "getEndDate",
    ]),
    ...mapMutations(["updateProlongations"]),
    editPeriod(index, period) {
      this.begin_date = period.begin_date;
      this.end_date = period.end_date;
      this.price = period.price;
      this.period_id = period.id;
      this.dialog = true;
      this.prolongation_num = index + 1;
    },

    addPeriod() {
      if (this.getCompleted()) return;

      if (this.periods.length === 0) {
        this.begin_date = this.getEndDate();
        this.period_id = -1;
      } else {
        this.begin_date = this.periods.slice(-1)[0].end_date;
        this.period_id = -1 * (this.periods.length + 1);
      }

      this.end_date = null;
      this.price = 0;
      this.adding = true;

      this.prolongation_num = this.getProlongations().length + 1;
      this.dialog = true;
    },

    savePeriod() {
      if (this.deleted && this.period_id < 0) {
        this.periods = this.periods.filter((period) => {
          return period.id != this.period_id;
        });

        this.updateProlongations(this.periods);
        this.clearPeriodProp();
        this.dialog = false;
        return;
      }

      let form_is_valid = this.$refs.form.validate();

      if (!form_is_valid) return;

      let period_find = this.periods.filter((period) => {
        return period.id === this.period_id;
      });

      if (period_find.length === 0) {
        this.periods.push({
          begin_date: this.begin_date,
          end_date: this.end_date,
          price: this.price,
          id: this.period_id,
        });

        this.clearPeriodProp();
      } else {
        period_find[0].begin_date = this.begin_date;
        period_find[0].end_date = this.end_date;
        period_find[0].price = this.price;
        period_find[0].deleted = this.deleted;
      }

      this.updateProlongations(this.periods);

      this.dialog = false;
      this.add_day_click = 0;
    },

    closeForm() {
      this.clearPeriodProp();
      this.dialog = false;
    },

    clearPeriodProp() {
      this.begin_date = null;
      this.end_date = null;
      this.price = 0;
      this.id = null;
      this.deleted = false;
      this.adding = false;
    },

    dateToString(date) {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        timezone: "UTC",
      };

      let result = new Date(date).toLocaleString("ru", options);

      return result;
    },

    btnTitle(index, period) {
      let date_diff = this.getNumberOfDays(period.begin_date, period.end_date);

      let begin = this.dateToString(period.begin_date);

      let end = this.dateToString(period.end_date);

      let result = `${index + 1}) ${begin.substring(0, 5)} - ${end.substring(
        0,
        5
      )}| ${date_diff} дн. | ${period.price} р.`;

      return result;
    },

    btnColor(period) {
      let dateNow = new Date();
      let dateEnd = new Date(period.end_date);

      let color = "";

      if (dateNow > dateEnd) color = "green lighten-4";
      else color = "orange lighten-4";

      return color;
    },

    btnDisabled(index) {
      if (!this.getCompleted()) {
        return false;
      } else if (index === this.periods.length - 1) {
        return false;
      } else return true;
    },

    getLatestPeriod() {
      if (this.periods.length === 0) return null;
      else return this.periods.slice(-1)[0];
    },

    getNumberOfDays(start, end) {
      const date1 = new Date(start);
      const date2 = new Date(end);

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
    },
  },

  mounted: function () {
    this.periods = this.getProlongations();
  },
};
</script>

<style>
</style>