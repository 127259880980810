import { apiUrl, getCookieToken, setCookie } from "../../conf";

export default {

    actions: {

        async fetchGetPredictPrice({ commit }, data) {

            const result = await fetch(apiUrl + '/api/preorders/predict_price?day_amount=' + data.day_amount + '&rent_type=' + data.rent_type, {

                headers: {

                    Token: getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (result.status === 200) {

                const price = await result.json()

                commit('updatePredictPrice', price)
            }
            else {
                commit('updatePredictPrice', 0)
            }

        },

        async fetchGetPredictDays({ commit }, rent_type) {

            const result = await fetch(apiUrl + '/api/preorders/predict_days?&rent_type=' + rent_type, {

                headers: {

                    Token: getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (result.status === 200) {

                const price = await result.json()

                commit('updatePredictDays', price)
            }
            else {
                commit('updatePredictDays', null)
            }

        },
    },

    mutations: {

        updatePredictPrice(state, val) {

            state.predict_price = val;
        },

        updatePredictDays(state, val) {

            state.predict_days = val;
        },
    },

    state: {
        predict_price: null,
        predict_days: null,
    },

    getters: {

        getPredictPrice(state) {

            return state.predict_price;
        },

        getPredictDays(state) {

            return state.predict_days;
        },
    },
}