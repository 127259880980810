<template>
  <v-form v-model="valid" ref="price_form" lazy-validation>
    <v-text-field
      label="Стоимость аренды"
      prefix="₽"
      :loading="loading_price"
      append-icon="mdi-cash"
      :rules="[
        () => !!price || 'Заполните поле',
        () => price > 0 || `Должно быть больше 0`,
      ]"
      v-model="price"
      type="number"
      step="100"
      clearable
    ></v-text-field>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      loading_price: false,
      price: null,
      predict_price: null,
      valid: true,
    };
  },

  watch: {
    price(val) {
      this.updatePrice(val);
    },
  },

  mounted: function () {
    this.price = this.getPrice();
  },

  methods: {
    ...mapGetters([
      "getPredictPrice",
      "getPrice",
      "getDelivered",
      "getRentType",
      "getBeginDate",
      "getEndDate",
      "getRentDaysAndPrices"
    ]),
    ...mapActions(["fetchGetPredictPrice"]),
    ...mapMutations(["updatePrice"]),

    validate() {
      this.$refs.price_form.validate();
      return this.valid;
    },

    async predictPrice() {
      if (this.getDelivered() || this.getPrice() != null || this.getRentType() === null) return;

      this.loading_price = true;

      this.predict_price = this.getRentDaysAndPrices()[this.getRentType()][this.getNumberOfDays()];

      if (this.predict_price > 0) this.price = this.predict_price;

      this.loading_price = false;
    },

    getNumberOfDays() {
      if (this.getBeginDate() === null || this.getEndDate() === null) return 0;

      const date1 = new Date(this.getBeginDate());
      const date2 = new Date(this.getEndDate());

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
    },
  },
};
</script>

<style>
</style>