<template>
  <div>
    <v-overlay :value="overlay_visable">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card v-if="loaded" max-width="800" class="mx-auto">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container>
          <v-row justify="center">
            <v-col sm="4" md="4" lg="4">
              <v-select
                label="Тип"
                v-model="console_type"
                :items="getConsoleTypeDictionary()"
                item-value="id"
                item-text="code"
                :rules="[() => !!console_type || 'Заполните поле']"
              ></v-select>
            </v-col>
            <v-col sm="4" md="4" lg="4">
              <v-text-field
                label="Номер"
                v-model="number"
                :rules="[() => !!number || 'Заполните поле']"
              ></v-text-field>
            </v-col>
            <v-col sm="4" md="4" lg="4">
              <v-text-field
                label="Объем HDD"
                v-model="hdd_size_gb"
                type="number"
                :rules="[() => !!hdd_size_gb || 'Заполните поле']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col sm="6" md="6" lg="6">
              <v-text-field
                label="Код модели"
                v-model="model"
                :rules="[() => !!model || 'Заполните поле']"
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="6" lg="6">
              <v-text-field
                label="Серийный номер"
                v-model="serial_number"
                :rules="[() => !!serial_number || 'Заполните поле']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col>
              <GamesSelect />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col>
              <GamePadsSelect />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col sm="12" md="12" lg="12">
              <v-textarea
                rows="2"
                label="Описание для клиентов"
                v-model="description"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col sm="12" md="12" lg="12">
              <v-textarea
                rows="5"
                label="Примечения"
                v-model="notes"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col sm="4" md="4" lg="4">
              <v-checkbox
                label="Требуется интернет для работы"
                v-model="permanent_net_connection"
              ></v-checkbox>
            </v-col>
            <v-col sm="4" md="4" lg="4">
              <v-checkbox
                label="Разрешено подключать к интернету"
                v-model="net_connection_allowed"
              ></v-checkbox>
            </v-col>
            <v-col sm="4" md="4" lg="4">
              <v-checkbox
                label="Приставка доступна"
                v-model="active"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn color="green" dark @click="save()">Сохранить</v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <v-snackbar
      v-model="snackbar_error"
      :timeout="snackbar_timeout"
      centered
      color="red darken-3"
      elevation="24"
      multi-line
    >
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar_error = false"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import GamesSelect from "./GamesSelect.vue";
import GamePadsSelect from "./GamePadsSelect.vue";

export default {
  props: ["id"],

  components: {
    GamesSelect,
    GamePadsSelect,
  },

  watch: {
    console_type() {
      this.updateState();
    },
  },

  data() {
    return {
      overlay_visable: false,
      loaded: false,
      valid: true,
      number: null,
      description: null,
      notes: null,
      model: null,
      hdd_size_gb: null,
      net_connection_allowed: false,
      permanent_net_connection: false,
      active: true,
      serial_number: null,
      console_type: null,

      snackbar_error: false,
      snackbar_timeout: 1500,
      snackbar_text: "",
    };
  },

  methods: {
    ...mapActions([
      "fetchGetGameConsole",
      "fetchConsoleType",
      "fetchGetDictGames",
      "fetchSaveGameConsole",
      "fetchGetAllGamePad",
    ]),
    ...mapGetters(["getGameConsoleObj", "getConsoleTypeDictionary"]),
    ...mapMutations(["updateConsole"]),

    async save() {
      try {
        if(!this.$refs.form.validate())
          return;

        this.overlay_visable = true;

        this.updateState();

        await this.fetchSaveGameConsole();

        this.overlay_visable = false;

        window.location.href = "/consoles";
      } catch (e) {
        this.snackbar_error = true;
        this.overlay_visable = false;
        this.snackbar_text =
          e.message != null && e.message != ""
            ? e.message
            : "Ошибка при сохранении данных";
      }
    },

    updateState() {
      var saveObj = {
        id: this.id,
        number: this.number,
        description: this.description,
        notes: this.notes,
        model: this.model,
        hdd_size_gb: parseInt(this.hdd_size_gb),
        net_connection_allowed: this.net_connection_allowed,
        permanent_net_connection: this.permanent_net_connection,
        active: this.active,
        serial_number: this.serial_number,
        console_type: this.console_type,
      };
      this.updateConsole(saveObj);
    },

    async load() {
      try {
        this.loaded = false;
        this.overlay_visable = true;
        await this.fetchConsoleType();
        await this.fetchGetDictGames();
        await this.fetchGetAllGamePad();

        if (this.id == null) {
          this.overlay_visable = false;
          this.loaded = true;
          return;
        }

        await this.fetchGetGameConsole(this.id);
        this.serial_number = this.getGameConsoleObj().serial_number;
        this.number = this.getGameConsoleObj().number;
        this.description = this.getGameConsoleObj().description;
        this.notes = this.getGameConsoleObj().notes;
        this.model = this.getGameConsoleObj().model;
        this.hdd_size_gb = this.getGameConsoleObj().hdd_size_gb;
        this.permanent_net_connection =
          this.getGameConsoleObj().permanent_net_connection;
        this.net_connection_allowed =
          this.getGameConsoleObj().net_connection_allowed;
        this.active = this.getGameConsoleObj().active;
        this.console_type = this.getGameConsoleObj().console_type;

        this.overlay_visable = false;
        this.loaded = true;
      } catch (e) {
        this.overlay_visable = false;
        this.loaded = false;
        this.snackbar_error = true;
        this.snackbar_text =
          e.message != null && e.message != ""
            ? e.message
            : "Ошибка при загрузке данных";
      }
    },
  },

  mounted: function () {
    this.load();
  },
};
</script>

<style>
</style>