<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="primary"
        x-small
        v-bind="attrs"
        v-on="on"
        @click="dialog_person_edit = true"
      >
        <v-icon>mdi-pen</v-icon>

        <PersonEditDialog
          ref="ref_person_edit_dialog_form"
          v-on:closeEvent="dialog_person_edit = false"
          :isEdit="true"
        />
      </v-btn>
    </template>
    <span>Изменить профиль</span>
  </v-tooltip>
</template>

<script>
import PersonEditDialog from "../Dialogs/PersonDialog.vue";

export default {
  data() {
    return {
      dialog_person_edit: false,
    };
  },

  components: {
    PersonEditDialog,
  },

  watch: {
    dialog_person_edit(val) {
      if (val) this.$refs.ref_person_edit_dialog_form.show();
    },
  },
};
</script>

<style>
</style>