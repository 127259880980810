<template>
  <v-autocomplete
    v-model="console"
    :items="getConsoles"
    :loading="isLoading"
    chips
    clearable
    hide-details
    hide-selected
    item-text="number"
    label="Консоль"
    hide-no-data
    deletable-chips
    append-icon="mdi-nintendo-game-boy"
    return-object
    :disabled="readonly"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title> Поиск консоли</v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="indigo darken-4"
        class="white--text"
        v-on="on"
        label
        dark
      >
        <v-icon left> mdi-nintendo-game-boy </v-icon>
        <span v-text="getTitle(item)"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        :color="item.current_rent === null ? 'indigo darken-4' : 'red'"
        class="text-h5 font-weight-light white--text"
      >
        {{ item.number }}
      </v-list-item-avatar>
      <v-list-item-content color="red">
        <v-list-item-title v-text="getTitle(item)"> </v-list-item-title>
        <v-list-item-subtitle v-text="getBusyText(item)">
        </v-list-item-subtitle>
        <v-list-item-subtitle v-text="getMissingGames(item)">
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon color="indigo">mdi-nintendo-game-boy</v-icon>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      console: null,
      isLoading: false,
      console_items: [],
      search_console: "",
    };
  },

  props: ["readonly"],

  computed: {
    getConsoles() {
      let list = [];

      list = this.getConsoleStatusList().filter((type) => {
        return type.console_type.code.toUpperCase() === this.getRentType()
         && type.active;
      });

      if (this.console != null) list.push(this.console);

      return list;
    },
  },
  watch: {
    console(val) {
      this.updateGameConsoleObj(val);
    },
  },

  mounted: function () {
    this.console = this.getSelectedGameConsoleForRent();
  },

  methods: {
    ...mapActions(["fetchGetConsoleStatus"]),
    ...mapGetters([
      "getConsoleStatusList",
      "getSelectedGamesForRent",
      "getSelectedGameConsoleForRent",
      "getRentType",
      "getGameConsoleId",
    ]),
    ...mapMutations(["updateGameConsoleObj"]),

    getTitle(item) {
      let text = `${item.console_type.code.toUpperCase()} № ${item.number} | ${
        item.gamepads.length
      } дж.`;

      if (item.permanent_net_connection) text += " | нужен wi-fi";

      return text;
    },

    getBusyText(item) {
      let busy_text = '';
      if (item.current_rent != null) 
        busy_text = `${
          item.current_rent.person.last_name
        } ${item.current_rent.person.first_name.charAt(0)}. до ${
          item.current_rent.end_date
        }`;
      if (item.current_rent === null && item.booked) 
        busy_text = `Предзаказ ${
          item.preorder.person.last_name
        } ${item.preorder.person.first_name.charAt(0)}. на ${
          item.preorder.begin_date
        }`;

        return busy_text;
       
    },

    getMissingGames(item) {
      let text = "";

      for (let game of this.getSelectedGamesForRent()) {
        let game_exist = item.games.filter((gama_name) => {
          return gama_name === game.name;
        });

        if (game_exist.length < 1) {
          text += ` ${game.name},`;
        }
      }

      if (text != "") {
        text = text.substring(0, text.length - 1);
        text = `Нет игр : ${text}`;
      }

      return text;
    },
  },
};
</script>

<style>
</style>