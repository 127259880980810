import { apiUrl, getCookieToken, setCookie } from "../../conf";

export default {

    actions:{

        async fetchGetAllConsole(ctx){

            const result = await fetch(apiUrl + '/api/consoles/', {

                headers:{

                    Token:getCookieToken()
                }
            })

            if(result.status == 401){

                setCookie('isauth',false);
                window.location.href =  "/login/";
            }

            if(!result.ok){

                throw  new Error(await result.text())

            }
            else {

                const list = await result.json()

                ctx.commit('updateConsoleList', list)
            }
        },

        async fetchGetConsoleStatus(ctx){

            const result = await fetch(apiUrl + '/api/v2/console/status', {

                headers:{

                    Token:getCookieToken()
                }
            })

            if(result.status == 401){

                setCookie('isauth',false);
                window.location.href =  "/login/";
            }

            if(!result.ok){

                throw  new Error(await result.text())

            }
            else {

                const list = await result.json()

                ctx.commit('updateConsoleStatusList', list)
            }
        }
    },

    state:{

        console_list:[],
        console_status_list:[]
    },

    mutations:{

        updateConsoleList(state, list){

            state.console_list = list
        },

        updateConsoleStatusList(state, list){

            state.console_status_list = list;
        }
    },

    getters:{

        getAllConsole(state){

            return state.console_list
        },

        getConsoleStatusList(state){

            return state.console_status_list
        }
    }
}