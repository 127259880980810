<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab key="peorder" > Dashboard </v-tab>
        <v-tab key="active"> За период</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
          <Dashboard ref="ref_preorder_tab"/>
          <ReportsFromPeriod ref="ref_active_tab"/>

      </v-tabs-items>
    </v-card>
  </div>
</template>


<script>
import ReportsFromPeriod from "./ReportsFromPeriod.vue";
import Dashboard from "./Dashboard.vue"

export default {

    components : {

        ReportsFromPeriod,
        Dashboard
    },
    
    data(){

        return{

            tab : null,
        }
    }
}
</script>

<style>

</style>