import Vue from 'vue'
import VueRouter from 'vue-router'
import PersonForm from '../views/PersonForm'
import RentComponent from "../components/Rent/RentConduct"
import RentsManagmentPanel from "../components/RentsManagment/RentManagmentComponent"
import ConsolesList from "../components/GameConsoles/ConsolesList";
import ConsoleForm from "../components/GameConsoles/ConsoleForm";
import LoginForm from "../components/Login"
import ReportsManagment from "../components/Reports/ReportsManagment.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: RentsManagmentPanel,
    meta: {

      title: 'Управление арендами'
    }
  },
  {
    path: '/consoles',
    component: ConsolesList,
    meta: {

      title: 'Список приставок'
    }
  },
  {
    path: '/person/:id',
    //component: getCookieIsAuth() ? PersonForm : LoginForm,
    component: PersonForm,
    props: true,
    meta: {

      title: 'Изменение информации о клиенте'
    }
  },
  {
    path: '/console/:id',
   // component: getCookieIsAuth() ? PersonForm : LoginForm,
    component: ConsoleForm,
    props: true,
    meta: {

      title: 'Изменение информации о приставке'
    }
  },
  {
    path: '/console/',
    //component: getCookieIsAuth() ? PersonForm : LoginForm,
    component: ConsoleForm,
    meta: {

      title: 'Добавление новой приставки'
    }
  },
  {
    path: '/person/',
   // component: getCookieIsAuth() ? PersonForm : LoginForm,
    component: PersonForm,
    meta: {

      title: 'Изменение информации о клиенте'
    }
  },
  {
    path: '/rent',
    component: RentComponent,
    meta: {

      title: 'Предзаказ Аренды'
    }
  },
  {
    path: '/rent/:id',
    component: RentComponent,
    props: true,
    meta: {

      title: 'Аренда'
    },

  },
  {
    path: '/login',
    component: LoginForm,
    props: false,
    meta: {

      title: 'Авторизация'
    }
  },
  {
    path: '/reports',
    component: ReportsManagment,
    props: false,
    meta: {

      title: 'Отчеты'
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router
