import { apiUrl } from "../conf";

export default {

    actions: {

        async fetchCheckFSSP({ commit }, person) {

            const result = await fetch(apiUrl + '/api/fssp_check?firstname=' + person.first_name + "&secondname=" + person.middle_name + "&lastname=" + person.last_name + "&birthdate=" + person.b_day,
                {

                    method: 'GET',
                    mode: "cors",
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: { "Content-Type": "application/json" },
                });

            if (result.ok) {

                let json = await result.json();

                commit('updateListFSSP', json)
            }
            else{

                commit('clearList');

                throw new Error('Ошибка при проверка по ФССП');
            }
        },
    },

    mutations: {

        updateListFSSP(state, fsspInfo) {

            if (fsspInfo.code == 0 &&
                fsspInfo.response != null &&
                fsspInfo.response.status == 0 &&
                fsspInfo.response.result.length > 0 &&
                fsspInfo.response.status === 0
            ) {

                state.list_fssp = fsspInfo.response.result[0].result;

            }
        },

        clearList(state){

            state.list_fssp = [];
        }
    },

    state: {

        list_fssp: [],
    },

    getters: {

        getFsspItems(state) {

            return state.list_fssp;
        },

        getFsspTotalSum(state) {

            var bar = {

                sumMatch(row) {
                    let mathes = null;
                    var pattern = /(\d+)+(.[0-9]{1,2})(\b руб\.)/;
                    mathes = row.match(pattern);
                    let result = 0.0;

                    if (mathes != null) {
                        let s1 = mathes[0];
                        result += bar.matchDigit(s1);

                        let s2 = row.substring(mathes.index + mathes[0].length) + " ";
                        mathes = s2.match(pattern);
                        if (mathes != null) result += bar.matchDigit(mathes[0]);
                    }

                    return result;
                },

                matchDigit(row) {
                    var pattern_digit = /(\d+)+(.[0-9]{1,2})/;
                    let digit = row.match(pattern_digit);
                    let result = 0.0;

                    if (digit != null && digit.length > 0) {
                        result = parseFloat(digit[0]);
                    }

                    return result;
                }
            }


            let fssp_sum = 0;
            for (let item of state.list_fssp) {
                if (item.subject != null && item.subject != "")
                    fssp_sum += bar.sumMatch(item.subject);
            }

            return fssp_sum;
        },


    }
}