<template>
  <v-dialog v-model="dialog" width="900px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <PersonComponent ref="ref_person_component" :id="null" v-if="dialog"  v-on:savePersonCompleteEvent="setNewPersonId"/>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonComponent from "../../../Person/PersonComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PersonComponent,
  },

  data() {
    return {
      dialog: false,
      new_person_id : null,
    };
  },

  computed: {
    title() {
        return "Создать клиента";
 
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.$emit("closeEvent", this.new_person_id);
      }
    },
  },

  methods: {
    show() {
      this.dialog = true;
    },

    ...mapGetters(["getSelectedPersonForRent"]),

    setNewPersonId(val){

        this.new_person_id = val;
        this.dialog = false;
    }

  },
};
</script>

<style>
</style>