<template>
  <v-form v-model="valid" ref="period_form" lazy-validation>
    <v-row>
      <v-col lg="4" md="8" sm="12">
        <v-text-field
          type="date"
          label="Дата начала"
          v-model="begin_date"
          :loading="loading_days"
          :rules="[() => !!begin_date || 'Заполните поле']"
          :readonly="getDelivered()"
        >
        </v-text-field>
          <v-btn
          x-small
          color="light-green lighten-4"
          style="margin-left: 10px"
          @click="getTomorrow()"
        >
          на завтра
          <v-icon x-small>mdi-calendar</v-icon>
        </v-btn>

      </v-col>
      <v-col lg="8" md="8" sm="12">
        <v-text-field
          type="date"
          label="Дата окончания"
          v-model="end_date"
          :rules="[() => !!end_date || 'Заполните поле']"
          :readonly="getDelivered()"
        >
        </v-text-field>
          <v-btn
          v-for="(day, index) in predict_days"
          :key="index"
          x-small
          color="light-green lighten-4"
          style="margin-left: 10px"
          @click="day_amount = day"
        >
          {{ day }} дн.
          <v-icon x-small>mdi-calendar</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      begin_date: new Date().toISOString().slice(0, 10),
      end_date: null,
      periods: [],
      loading_days: false,
      predict_days: [],
      day_amount: 0,
      valid: true,
    };
  },

  watch: {
    day_amount(newVal) {
      if (newVal === 0) return;

      if (this.begin_date != null) {
        let date_start = new Date(Date.parse(this.begin_date));

        let end = date_start;

        end.setDate(date_start.getDate() + parseInt(newVal));

        this.end_date = end.toISOString().slice(0, 10);
      }
    },

    begin_date(val) {
      this.day_amount = 0;

      let date_start = new Date(Date.parse(val));
      let date_end = new Date(Date.parse(this.end_date));

      if (date_start.getTime() > date_end.getTime()) {
        this.end_date = null;
      }

      this.updateBeginDate(val);
      this.periodChange();
    },

    end_date(val) {
      this.updateBeginDate(this.begin_date);
      this.updateEndDate(val);
      this.periodChange();
    },
  },

  methods: {
    ...mapGetters([
      "getPredictDays",
      "getPeriods",
      "getDelivered",
      "getRentType",
      "getBeginDate",
      "getEndDate",
      "getRentDaysAndPrices"
    ]),
    ...mapMutations(["updateBeginDate", "updateEndDate"]),
    ...mapActions(["fetchGetPredictDays",]),

    validate() {
      this.$refs.period_form.validate();
      return this.valid;
    },

    getTomorrow(){

        let now = new Date();

        this.begin_date = new Date(now.getFullYear(), now.getMonth() ,now.getDate() + 2).toISOString().slice(0, 10)
    },

    async predictDays() {
      if (this.getDelivered() || this.getEndDate() != null) return;

      this.loading_days = true;


      for(let day in  this.getRentDaysAndPrices()[this.getRentType()]){

   
           this.predict_days.push(day);
      }

      //await this.fetchGetPredictDays(this.getRentType());

     // this.predict_days = this.getPredictDays().sort();

      this.loading_days = false;
    },

    periodChange() {
      this.$emit("periodChangeEvent", this.getNumberOfDays());
    },

    getNumberOfDays() {
      if (this.begin_date === null || this.end_date === null) return 0;

      const date1 = new Date(this.begin_date);
      const date2 = new Date(this.end_date);

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay);

      return diffInDays;
    },
  },

  mounted: function () {
    this.begin_date =
      this.getBeginDate() === null
        ? new Date().toISOString().slice(0, 10)
        : this.getBeginDate();
    this.end_date = this.getEndDate();

  },
};
</script>

<style>
</style>