<template>
  <v-autocomplete
    v-model="games_selected"
    :items="games_items"
    :loading="isLoading"
    chips
    clearable
    hide-details
    hide-selected
    item-text="name"
    item-value="symbol"
    label="Интересующие игры"
    hide-no-data
    multiple
    deletable-chips
    append-icon="mdi-controller-classic-outline"
    return-object
    :disabled="readonly"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title> Поиск игр</v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="indigo darken-4"
        class="white--text"
        v-on="on"
        label
        dark
        small
        close
        @click:close="closeChips(item)"
      >
        <v-icon left> mdi-controller-classic </v-icon>
        <span v-text="item.name"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="indigo darken-4"
        class="text-h5 font-weight-light white--text"
      >
        {{ item.name.charAt(0) }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name"></v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon color="indigo">mdi-controller-classic</v-icon>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      games_selected: [],
      isLoading: false,
      games_items: [],
    };
  },

  props: ["readonly"],

  watch: {
    games_selected(newVal) {
      this.updateSelectedGamesForRent(newVal);
    },
  },

  methods: {
    ...mapGetters(["getGamesDictionary", "getSelectedGamesForRent"]),
    ...mapMutations(["updateSelectedGamesForRent"]),

    closeChips(val) {
      let list = [];

      for (let game of this.games_selected) {
        if (val.id != game.id) list.push(game);
      }

      this.games_selected = list;
    },
  },

  created: async function () {
    this.isLoading = true;
    this.games_items = this.getGamesDictionary().sort();
    this.games_selected = this.getSelectedGamesForRent();
    this.isLoading = false;
  },
};
</script>

<style>
</style>