import { apiUrl, getCookieToken, setCookie } from "../conf";

export default {

    actions: {

        async fetchGetDictGames({ commit }) {

            const result = await fetch(apiUrl + '/api/dict/games', {

                headers: {

                    // Token:getCookieToken()
                }
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const item = await result.json()

                commit('updateGamesDictionary', item)
            }
        },

        async fetchConsoleType({ commit }) {

            const result = await fetch(apiUrl + '/api/dict/console_type')

            const list = await result.json()

            commit('updateConsoleTypeDictionary', list)
        },

        async fetchAddDictGames({ commit }, name) {

            const result = await fetch(apiUrl + '/api/dict/games', {

                method: 'POST',
                body: JSON.stringify({ name: name }),
                headers: { "Content-Type": "application/json", Token: getCookieToken() },
            })

            if (result.status == 401) {

                setCookie('isauth', false);
                window.location.href = "/login/";
            }

            if (!result.ok) {

                throw new Error(await result.text())

            }
            else {

                const item = await result.json()

                commit('addGame', item)
            }
        },

        async fetchRentTypeDic({ commit }) {

            const result = await fetch(apiUrl + '/api/dict/rent_type')

            if (result.status === 200) {
                const list = await result.json()

                commit('updateRentTypeDictionary', list)
            }
        },

        async fetchRentDaysAndPrices({ commit }) {

            const result = await fetch(apiUrl + '/api/dict/rent_predict')

            if (result.status === 200) {
                const data = await result.json()

                commit('updateRentDaysAndPrices', data)
            }
        },
    },

    mutations: {

        updateGamesDictionary(state, list) {

            state.games_dict = list;
        },

        updateConsoleTypeDictionary(state, list) {

            state.console_type_dict = list;
        },

        updateRentTypeDictionary(state, list) {

            state.rent_type = list;
        },

        updateRentDaysAndPrices(state, data){

            state.rent_days_and_prices = data;
        }

    },

    state: {

        games_dict: [],
        console_type_dict: [],
        new_game: [],
        rent_type: [],
        rent_days_and_prices : null,
    },

    getters: {

        getGamesDictionary(state) {

            return state.games_dict;
        },

        getConsoleTypeDictionary(state) {

            return state.console_type_dict;
        },

        getNewGame(state) {

            return state.new_game;
        },

        getRentTypeDictionary(state) {

            return state.rent_type;
        },

        getRentDaysAndPrices(state){

            return state.rent_days_and_prices;
        }

    }
}