<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="green"
        x-small
        style="margin-left: 10px"
        v-bind="attrs"
        v-on="on"
        @click="openWhatsApp"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </template>
    <span>открыть диалог в whatsapp</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    ...mapGetters(["getSelectedPersonForRent"]),

    openWhatsApp() {
      window.open(
        "https://api.whatsapp.com/send?phone=" +
          this.getSelectedPersonForRent().phone_number +
          "&text=."
      );
    },
  },
};
</script>

<style>
</style>