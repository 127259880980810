<template>
  <div>
    <v-overlay :value="overlay_visable">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card v-if="loaded" flat style="margin-top: 15px">
      <v-card-subtitle>
                      <v-card-actions>
        <v-btn x-small color="green" dark href="/console">Добавить консоль</v-btn>
      </v-card-actions>
        Быстрые фильтры:
        <v-card-actions>
          <v-btn-toggle v-model="toggle_exclusive">
            <v-row>
              <v-col lg="4"
                ><v-btn
                  x-small
                  color="blue-grey lighten-5"
                  @click="end_today_view()"
                  >Освободятся сегодня ({{ end_today_count }})</v-btn
                >
              </v-col>

              <v-col lg="4">
                <v-btn
                  x-small
                  color="blue-grey lighten-5"
                  @click="end_tomorrow_view()"
                  >Освободятся завтра ({{ end_tomorrow_count }})</v-btn
                >
              </v-col>

              <v-col lg="4">
                <v-btn
                  x-small
                  color="blue-grey lighten-5"
                  @click="all_free_view()"
                  >Свободные ({{ all_free_count }})</v-btn
                >
              </v-col>

              <v-col lg="2">
                <v-btn x-small color="blue-grey lighten-5" @click="ps4_view()"
                  >PS4 ({{ ps4_count }})</v-btn
                >
              </v-col>
              <v-col lg="2">
                <v-btn x-small color="blue-grey lighten-5" @click="ps5_view()"
                  >PS5 ({{ ps5_count }})</v-btn
                >
              </v-col>
              <v-col lg="1">
                <v-btn
                  x-small
                  color="blue-grey lighten-5"
                  @click="all_active_view()"
                  >Все ({{ all_count }})</v-btn
                >
              </v-col>
            </v-row>
          </v-btn-toggle>
        </v-card-actions>
      </v-card-subtitle>
      <v-container fluid>
        <v-row dense>
          <v-col
            lg="2"
            style="margin-top: 15px"
            v-for="game_console in consoles_view"
            :key="game_console.id"
          >
            <GameConsoleCard v-bind:game_console="game_console" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GameConsoleCard from "./GameConsoleCard.vue";
export default {
  data() {
    return {
      overlay_visable: false,
      consoles: [],
      loaded: false,
      toggle_exclusive: 5,
      consoles_view: [],
    };
  },

  components: {
    GameConsoleCard,
  },

  computed: {
    end_today_count() {
      return this.consoles.filter((c) => {
        return c.current_rent != null && c.current_rent.day_left <= 0;
      }).length;
    },

    end_tomorrow_count() {
      return this.consoles.filter((c) => {
        return c.current_rent != null && c.current_rent.day_left === 1;
      }).length;
    },

    all_free_count() {
      return this.consoles.filter((c) => {
        return c.current_rent === null && c.active === true;
      }).length;
    },

    ps4_count() {
      return this.consoles.filter((c) => {
        return c.console_type.code.toUpperCase() === "PS4";
      }).length;
    },

    ps5_count() {
      return this.consoles.filter((c) => {
        return c.console_type.code.toUpperCase() === "PS5";
      }).length;
    },

    all_count() {
      return this.consoles.length;
    },
  },

  methods: {
    ...mapActions(["fetchGetConsoleStatus"]),
    ...mapGetters(["getConsoleStatusList"]),

    end_today_view() {
      this.consoles_view = this.consoles.filter((c) => {
        return c.current_rent != null && c.current_rent.day_left <= 0;
      })
    },

    end_tomorrow_view() {
      this.consoles_view = this.consoles.filter((c) => {
        return c.current_rent != null && c.current_rent.day_left === 1;
      })
    },

    all_free_view() {
      this.consoles_view = this.consoles.filter((c) => {
        return c.current_rent === null && c.active === true;
      });
    },

    ps4_view() {
      this.consoles_view = this.consoles.filter((c) => {
        return c.console_type.code.toUpperCase() === "PS4";
      });
    },

    ps5_view() {
      this.consoles_view = this.consoles.filter((c) => {
        return c.console_type.code.toUpperCase() === "PS5";
      });
    },

    all_active_view() {
      this.consoles_view = this.consoles;
    },
  },

  mounted: async function () {
    this.overlay_visable = true;
    await this.fetchGetConsoleStatus();
    this.consoles = this.getConsoleStatusList();
    this.consoles_view = this.consoles;
    this.overlay_visable = false;
    this.loaded = true;
  },
};
</script>

<style>
</style>