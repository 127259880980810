<template>
  <v-dialog v-model="dialog" width="900px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <PersonComponent ref="ref_person_component" :id="id" v-if="dialog"  v-on:savePersonCompleteEvent="dialog = false"/>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonComponent from "../../../Person/PersonComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PersonComponent,
  },

  data() {
    return {
      dialog: false,
    };
  },

  props: ["isEdit"],

  computed: {
    title() {
      if (this.id != undefined && this.id != null) {
        return "Профиль клиента";
      } else {
        return "Создать клиента";
      }
    },

    id() {
      if (
        this.isEdit != undefined &&
        this.isEdit != null &&
        this.isEdit &&
        this.getSelectedPersonForRent() != null
      )
        return this.getSelectedPersonForRent().id;
      else return null;
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.$emit("closeEvent");
      }
    },
  },

  methods: {
    show() {
      this.dialog = true;
    },

    ...mapGetters(["getSelectedPersonForRent"]),
  },
};
</script>

<style>
</style>