<template>
  <div style="max-width: 900px; margin: auto">
    <v-overlay :value="overlay_visable">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="loaded">
      <v-row justify="center">
        <v-col cols="12" sm="10" md="10" lg="10">
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <PersonSelect
                    v-on:personSelectedEvent="personSelected"
                    ref="ref_person_select"
                    :readonly="delivered"
                  />
                </v-col>
              </v-row>
              <RentButtons />
              <br />

              <v-expansion-panels v-model="panel">
                <v-expansion-panel flat>
                  <v-expansion-panel-header>
                    {{ expansionHeader }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col lg="6" md="6" sm="8">
                        <RentSourceSelect ref="rent_source_ref" />
                      </v-col>
                      <v-col lg="6" md="6" sm="12">
                        <RentTypeSelect
                          v-on:changeTypeEvent="changeType"
                          ref="rent_type_ref"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="12" md="12" sm="12">
                        <PeriodInput
                          ref="period_input_ref"
                          v-on:periodChangeEvent="changePeriod"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="12" md="12" sm="12">
                        <GamesSelect :readonly="delivered" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col lg="4" md="8" sm="6">
                        <GameConsoleSelect :readonly="delivered" />
                      </v-col>
                      <v-col lg="8" md="8" sm="6">
                        <PriceInput
                          style="margin-top: 15px"
                          ref="price_input_ref"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <DeliveryAddressSelect ref="select_address_ref" />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row>
                <br />
                <v-col cols="12" md="12">
                  <CommentInput />
                </v-col>
              </v-row>
              <br />
              <Prolongation v-if="delivered" />
              <v-row>
                <v-col cols="12" md="12">
                  <v-checkbox
                    v-model="delivered"
                    label="Оборудование выдано"
                    :rules="deliveredRules"
                    :readonly="deliveredReadOnly"
                    :disabled="deliveredDisable"
                  >
                  </v-checkbox>
                  <v-checkbox
                    v-model="completed"
                    label="Аренда завершена"
                    :disabled="completedDisabled"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn color="teal lighten-2" @click="save" dark> Сохранить </v-btn>
        <v-snackbar
          v-model="snackbar_error"
          :timeout="snackbar_timeout"
          centered
          color="red darken-3"
          elevation="24"
          multi-line
        >
          {{ snackbar_text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar_error = false"
            >
              Закрыть
            </v-btn>
          </template>
        </v-snackbar>
      </v-row>
    </div>
    <div v-if="loaded_error">
      <ErrorOpen />
    </div>
  </div>
</template>

<script>
import PersonSelect from "./Person/PersonSelect.vue";
import GamesSelect from "./Components/GamesSelect.vue";
import DeliveryAddressSelect from "./Components/DeliveryAddressSelect.vue";
import GameConsoleSelect from "./Components/GameConsoleSelect.vue";
import Prolongation from "./Components/ProlongationComponent.vue";
import RentTypeSelect from "./Components/RentTypeSelect.vue";
import RentSourceSelect from "./Components/RentSourceSelect.vue";
import PeriodInput from "./Components/PeriodInput.vue";
import PriceInput from "./Components/PriceInput.vue";
import CommentInput from "./Components/CommentInput.vue";
import RentButtons from "./Components/RentButtons.vue";
import ErrorOpen from "../Error.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    PersonSelect,
    GamesSelect,
    DeliveryAddressSelect,
    GameConsoleSelect,
    Prolongation,
    RentTypeSelect,
    RentSourceSelect,
    PeriodInput,
    PriceInput,
    CommentInput,
    RentButtons,
    ErrorOpen,
  },
  data() {
    return {
      valid: false,
      loaded: true,
      loaded_error: false,
      overlay_visable: false,
      loading_contract: false,
      loading_save: false,
      panel: -1,
      person_id: null,
      game_console_id: null,
      delivered: false, // true - консоль передана клинету. предзаказ выполнен.,
      completed: false,
      person_id_with_dialog: null,
      snackbar_error: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
    };
  },

  props: ["id"],

  computed: {
    expansionHeader() {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        timezone: "UTC",
      };

      let result = "";

      if (
        this.getRentType() != null &&
        this.getBeginDate() != null &&
        this.getEndDate() != null
      ) {
        let begin = new Date(this.getBeginDate()).toLocaleString("ru", options);

        let end = new Date(this.getEndDate()).toLocaleString("ru", options);

        result = `${this.getRentType()} | с ${begin} по ${end} | ${this.getPrice()} руб.`;
      } else {
        result = "Данные о предзаказе";
      }

      return result;
    },

    deliveredRules() {
      const rules = [];

      if (this.delivered && this.getSelectedGameConsoleForRent() === null) {
        let rule = () =>
          this.getSelectedGameConsoleForRent() != null ||
          `Предзаказ не может быть выполнен, пока не выбрана приставка`;
        rules.push(rule);
      }

      return rules;
    },

    contractBtnDisabled() {
      return true;
    },

    completedDisabled() {
      let disabled = false;
      let dateNow = new Date();

      if (this.delivered && this.getSelectedGameConsoleForRent() === null) {
        disabled = true;
      } else if (!this.delivered) {
        disabled = true;
      } else if (this.getEndDate() != null) {
        let dateEnd = new Date(this.getEndDate());

        if (dateNow < dateEnd) disabled = true;
      }

      return disabled;
    },

    deliveredDisable() {
      return this.getSelectedGameConsoleForRent() === null;
    },

    deliveredReadOnly() {
      if (
        this.getRentObjLoaded() != null &&
        this.getRentObjLoaded().delivered
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    description(val) {
      this.updateDescription(val);
    },

    delivered(val) {
      this.updateDelivered(val);
    },

    completed(val) {
      this.updateCompleted(val);
    },
  },

  methods: {
    ...mapGetters([
      "getRentType",
      "getConsoleStatusList",
      "getBlobContract",
      "getRentObj",
      "getPersonObj",
      "getRentObjLoaded",
      "getGamesDictionary",
      "getSelectedGameConsoleForRent",
      "getConsoleById",
      "getCompleted",
      "getBeginDate",
      "getEndDate",
      "getPrice",
      "getDelivered",
    ]),
    ...mapActions([
      "fetchRentTypeDic",
      "fetchGetConsoleStatus",
      "fetchGetRent",
      "fetchGetDictGames",
      "fetchSavePreOrder",
      "fetchDownloadContract",
      "fetchGetPersonById",
      "fetchGetViewConsoleByIdV2",
      "fetchRentDaysAndPrices",
    ]),
    ...mapMutations([
      "updateDelivered",
      "updateDeliveryAddress",
      "updatePersonObj",
      "updateSelectedGamesForRent",
      "updateGameConsoleObj",
      "updateCompleted",
    ]),

    changeType() {
      this.$refs.period_input_ref.predictDays();
    },

    changePeriod() {
      this.$refs.price_input_ref.predictPrice();
    },

    clickNewPerson() {
      this.person_id_with_dialog = null;
    },

    clickEditPerson() {
      this.person_id_with_dialog = this.person_id;
    },

    personSelected(item) {
      if (item != null && !this.delivered) {
        this.$refs.select_address_ref.loadHistoryAddress(item.id);
      }
    },

    async save() {
      let form_is_valid = this.$refs.form.validate();

      let person_is_valid = this.$refs.ref_person_select.validate();

      let price_is_valid = this.$refs.price_input_ref.validate();

      let rent_source_is_valid = this.$refs.rent_source_ref.validate();

      let retn_type_is_valid = this.$refs.rent_type_ref.validate();

      if (
        !form_is_valid ||
        !person_is_valid ||
        !price_is_valid ||
        !rent_source_is_valid ||
        !retn_type_is_valid
      )
        return;

      try {
        this.overlay_visable = true;

        await this.fetchSavePreOrder();

        window.location.href = '/';
      } catch (e) {
        this.snackbar_text = e.message;
        this.snackbar_error = true;
      } finally {
        this.overlay_visable = false;
      }
    },

    async load() {
      try {
        this.loaded = false;
        this.overlay_visable = true;
        let game_console_obj = null;
        let games = [];

        if (this.id != null) await this.fetchGetRent(this.id);
        else await this.fetchRentDaysAndPrices();

        await this.fetchRentTypeDic();
        await this.fetchGetDictGames();

        //Грузим инфу о консолях
        if (
          this.getRentObjLoaded() != null &&
          this.getRentObjLoaded().delivered &&
          this.getRentObjLoaded().game_console != null
        ) {
          await this.fetchGetViewConsoleByIdV2(
            this.getRentObjLoaded().game_console
          );

          game_console_obj = this.getConsoleById();
        } else {
          await this.fetchGetConsoleStatus();

          if (
            this.getRentObjLoaded() != null &&
            this.getRentObjLoaded().game_console != null
          )
            game_console_obj = this.getConsoleStatusList().filter((console) => {
              return console.id === this.getRentObjLoaded().game_console;
            })[0];
        }

        if (this.getRentObjLoaded() != null) {
          await this.fetchGetPersonById(this.getRentObjLoaded().person);
          this.updatePersonObj(this.getPersonObj());

          for (let game_id of this.getRentObjLoaded().games) {
            let game_item = this.getGamesDictionary().filter((game) => {
              return game.id == game_id;
            });

            if (game_item != null && game_item.length > 0)
              games.push(game_item[0]);
          }
        }

        this.delivered = this.getDelivered();
        this.completed = this.getCompleted();

        this.updateSelectedGamesForRent(games);
        this.updateGameConsoleObj(game_console_obj);
        this.loaded = true;
        this.overlay_visable = false;

        this.panel = 0;
      } catch (e) {
        this.loaded = false;
        this.overlay_visable = false;
        this.loaded_error = true;
        console.log(e.message);
      }
    },
  },

  mounted: function () {
    this.load();
  },
};
</script>

<style>
</style>