<template>
  <v-btn
    :loading="loading_history"
    :color="color_history_btn"
    x-small
    dark
    style="margin-left: 10px"
    @click="show()"
  >
    аренды
    <template>
      <RentHistory ref="rent_history_dialog" v-on:closeEvent="dialog = false" />
    </template>
  </v-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RentHistory from "../Dialogs/RentHistory.vue";

export default {
  components: {
    RentHistory,
  },

  watch: {
    dialog(val) {
      if (val) this.$refs.rent_history_dialog.show();
    },
  },

  methods: {
    ...mapGetters(["getSelectedPersonForRent", "getOrderHistoryByPerson"]),
    ...mapActions(["fetchOrderHistoryByPerson"]),

    show() {
      if (this.getSelectedPersonForRent() === null) return;

      this.dialog = true;
    },

    async getRents() {
      if (this.getSelectedPersonForRent() === null) {
        this.color_history_btn = "primary";
        return;
      }

      this.loading_history = true;
      this.color_history_btn = "blue-grey lighten-3";

      await this.fetchOrderHistoryByPerson(this.getSelectedPersonForRent().id);
      this.rents = this.getOrderHistoryByPerson();

      if (this.rents.length > 0)
        this.color_history_btn = "light-blue lighten-2";
      else this.color_history_btn = "grey darken-1";

      this.loading_history = false;
    },
  },

  data() {
    return {
      loading_history: false,
      rents: [],
      color_history_btn: "primary",
      dialog: false,
    };
  },
};
</script>

<style>
</style>