var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.getConsoles,"loading":_vm.isLoading,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"number","label":"Консоль","hide-no-data":"","deletable-chips":"","append-icon":"mdi-nintendo-game-boy","return-object":"","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Поиск консоли")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"indigo darken-4","label":"","dark":""}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-nintendo-game-boy ")]),_c('span',{domProps:{"textContent":_vm._s(_vm.getTitle(item))}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":item.current_rent === null ? 'indigo darken-4' : 'red'}},[_vm._v(" "+_vm._s(item.number)+" ")]),_c('v-list-item-content',{attrs:{"color":"red"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getTitle(item))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.getBusyText(item))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.getMissingGames(item))}})],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-nintendo-game-boy")])],1)]}}]),model:{value:(_vm.console),callback:function ($$v) {_vm.console=$$v},expression:"console"}})}
var staticRenderFns = []

export { render, staticRenderFns }