<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="deep-purple lighten-1"
        x-small
        style="margin-left: 10px"
        v-bind="attrs"
        v-on="on"
        @click="openSocial"
      >
        <v-icon>mdi-web</v-icon>
      </v-btn>
    </template>
    <span>перейти на {{ social_url }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    ...mapGetters(["getSelectedPersonForRent"]),

    openSocial() {
      window.open(this.getSelectedPersonForRent().social_url);
    },
  },

  computed: {
    social_url() {
      return this.getSelectedPersonForRent() === null
        ? null
        : this.getSelectedPersonForRent().social_url;
    },
  },
};
</script>

<style>
</style>