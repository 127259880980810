var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"items":_vm.games_items,"loading":_vm.isLoading,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"symbol","label":"Игры","multiple":"","deletable-chips":"","append-icon":"mdi-controller-classic-outline","return-object":"","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-action',[_vm._v("Поиск игр")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"indigo darken-4","label":"","dark":"","small":"","close":""},on:{"click:close":function($event){return _vm.closeChips(item)}}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-controller-classic ")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo darken-4"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v("mdi-controller-classic")])],1)]}}]),model:{value:(_vm.games_selected),callback:function ($$v) {_vm.games_selected=$$v},expression:"games_selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }